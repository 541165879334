import React, { useEffect } from "react";

const RemoveLoadPopUp = props => {
  useEffect(() => {
    if (props.id === null) {
      props.toggle(4);
    }
  }, []);

  return (
    <div className="fixedPopUp">
      <div className="removeLoadPopUp">
        <h1>Are you sure you want to delete this load?</h1>
        <div className="btnRow">
          <div className="btnRowFiller"></div>
          <div onClick={() => props.toggle(4)} className="cancelBtn">
            Cancel
          </div>
          <div onClick={() => props.remove(props.id)} className="postBtn">
            Delete Load
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemoveLoadPopUp;
