import React, { useState, useEffect } from "react";
import DatePicker from "react-date-picker";

const SingleLoadPopUp = props => {
  const { load, open, nearlyComplete, master, _admin, adminObj, addLabel } = props;

  const [single, setLoad] = useState({
    today: new Date(),
    admin: load.booked_by,
    g: load.g,
    carrier: load.carrier,
    mc: load.mc,
    customer: load.customer,
    dispatcher: load.dispatcher_name,
    phone: load.dispatcher_phone,
    email: load.dispatcher_email,
    origin: load.origin,
    destination: load.destination,
    originState: load.origin_state,
    destinationState: load.destination_state,
    PUDate: new Date(load.pu_date_js),
    PUTime: load.pu_time,
    PUflex: load.pu_flex,
    DODate: new Date(load.do_date_js),
    DOTime: load.do_time,
    DOflex: load.do_flex,
    equipment: load.equipment,
    commodity: load.commodity,
    weight: load.weight,
    tarp: load.tarp,
    dims: load.dims,
    gwrate: load.gw_rate,
    truckrate: load.truck_rate,
    rateFlex: load.truck_rate_flex,
    delivered: load.delivered_date,
    scanned: load.scanned_date,
    paidDate: load.paid_date,
    notes: load.notes,
    label: load.label,
    loadID: load._id
  });

  const {
    customer,
    origin,
    destination,
    originState,
    destinationState,
    PUTime,
    PUflex,
    DOTime,
    DOflex,
    equipment,
    commodity,
    weight,
    tarp,
    dims,
    gwrate,
    truckrate,
    rateFlex,
    notes,
    label,
    PUDate,
    DODate,
    today,
    g,
    carrier,
    mc,
    dispatcher,
    phone,
    email,
    paidDate,
    delivered,
    scanned,
    admin
  } = single;

  const onChange = e => setLoad({ ...single, [e.target.name]: e.target.value });

  const onChangeCaps = e =>
    setLoad({ ...single, [e.target.name]: e.target.value.toUpperCase() });

  const changeFlexPU = () => {
    setLoad({ ...single, PUflex: !PUflex });
  };
  const changeFlexDO = () => {
    setLoad({ ...single, DOflex: !DOflex });
  };
  const changeRateFlex = () => {
    setLoad({ ...single, rateFlex: !rateFlex });
  };
  const handlePUDate = e => {
    setLoad({ ...single, PUDate: e });
  };
  const handleDODate = e => {
    setLoad({ ...single, DODate: e });
  };

  const handleEquipment = e => {
    setLoad({ ...single, equipment: e.target.value });
  };

  const onPost = () => {
    props.add(single);
    props.toggle(9);
  };

  return (
    <div className="fixedPopUp">
      <div className="singleLoadPopUp">
        <div className={open ? "singleOpenLoadNone" : "inputRow1 inputRow"}>
          <div>
            <label>G Number</label>
            <input type="text" name="g" value={g} onChange={onChangeCaps} />
          </div>
          <div>
            <label>Carrier</label>
            <input
              type="text"
              name="carrier"
              value={carrier}
              onChange={onChangeCaps}
            />
          </div>
          <div>
            <label>MC Number</label>
            <input type="text" name="mc" value={mc} onChange={onChangeCaps} />
          </div>
        </div>
        <div className={open ? "singleOpenLoadNone" : "inputRow1 inputRow"}>
          <div>
            <label>Dispatcher</label>
            <input
              type="text"
              name="dispatcher"
              value={dispatcher}
              onChange={onChangeCaps}
            />
          </div>
          <div>
            <label>Email</label>
            <input
              type="text"
              name="email"
              value={email}
              onChange={onChangeCaps}
            />
          </div>
          <div>
            <label>Phone Number</label>
            <input
              type="text"
              name="phone"
              value={phone}
              onChange={onChangeCaps}
            />
          </div>
        </div>
        <div className="newInputRow1 inputRow">
          {adminObj ? (
            adminObj.guest ? null : (
              <div style={{ width: "25%" }}>
                <label>Customer</label>
                <input
                  type="text"
                  name="customer"
                  value={customer}
                  onChange={onChangeCaps}
                />
              </div>
            )
          ) : null}
          <div style={{ width: "23%" }}>
            <label>Origin City</label>
            <input
              type="text"
              name="origin"
              value={origin}
              onChange={onChangeCaps}
            />
          </div>
          <div style={{ width: "10%" }}>
            <label>Origin State</label>
            <input
              type="text"
              name="originState"
              value={originState}
              onChange={onChangeCaps}
            />
          </div>
          <div style={{ width: "23%" }}>
            <label>Destination City</label>
            <input
              type="text"
              name="destination"
              value={destination}
              onChange={onChangeCaps}
            />
          </div>
          <div style={{ width: "10%" }}>
            <label>Dest. State</label>
            <input
              type="text"
              name="destinationState"
              value={destinationState}
              onChange={onChangeCaps}
            />
          </div>
        </div>
        <div className="inputRow2 inputRow">
          <div>
            <label>Pick-Up Date</label>
            <div className="datePickerDiv">
              <DatePicker
                value={PUDate}
                onChange={handlePUDate}
                clearIcon={null}
                calendarIcon={null}
                className="xxDatePicker"
              />
            </div>
          </div>
          <div>
            <label>Pick-Up Time</label>
            <input
              type="text"
              name="PUTime"
              value={PUTime}
              onChange={onChangeCaps}
            />
          </div>
          <div style={{ width: "5%" }}>
            <label>Flex?</label>
            <div onClick={changeFlexPU} className={PUflex ? "flexOn" : "flex"}>
              <i className="fas fa-check checkIconPopUp"></i>
            </div>
          </div>
          <i className="fas fa-long-arrow-alt-right arrowIcon"></i>
          <div>
            <label>Drop-Off Date</label>
            <div className="datePickerDiv">
              <DatePicker
                value={DODate}
                onChange={handleDODate}
                clearIcon={null}
                calendarIcon={null}
                className="xxDatePicker"
              />
            </div>
          </div>
          <div>
            <label>Drop-Off Time</label>
            <input
              type="text"
              name="DOTime"
              value={DOTime}
              onChange={onChangeCaps}
            />
          </div>
          <div style={{ width: "5%" }}>
            <label>Flex?</label>
            <div onClick={changeFlexDO} className={DOflex ? "flexOn" : "flex"}>
              <i className="fas fa-check checkIconPopUp"></i>
            </div>
          </div>
        </div>
        <div className="inputRow3 inputRow">
          <div>
            <label>Equipment</label>
            <select onChange={handleEquipment}>
              <option
                name="equipment"
                value="None"
                selected={equipment === "None" ? true : false}
              >
                None
              </option>
              <option
                name="equipment"
                value="Flatbed"
                selected={equipment === "Flatbed" ? true : false}
              >
                Flatbed
              </option>
              <option
                name="equipment"
                value="SD"
                selected={equipment === "SD" ? true : false}
              >
                SD
              </option>
              <option
                name="equipment"
                value="FSD"
                selected={equipment === "FSD" ? true : false}
              >
                FSD
              </option>
              <option
                name="equipment"
                value="53' flatbed"
                selected={equipment === "53' flatbed" ? true : false}
              >
                53' flatbed
              </option>
              <option
                name="equipment"
                value="53' SD"
                selected={equipment === "53' SD" ? true : false}
              >
                53' SD
              </option>
              <option
                name="equipment"
                value="53' SD Con"
                selected={equipment === "53' SD Con" ? true : false}
              >
                53' SD Con
              </option>
              <option
                name="equipment"
                value="53' flatbed Con"
                selected={equipment === "53' flatbed Con" ? true : false}
              >
                53' flatbed Con
              </option>
              <option
                name="equipment"
                value="53' Van"
                selected={equipment === "53' Van" ? true : false}
              >
                53' Van
              </option>
              <option
                name="equipment"
                value="48' flatbed Con"
                selected={equipment === "48' flatbed Con" ? true : false}
              >
                48' flatbed Con
              </option>
              <option
                name="equipment"
                value="48' SD Con"
                selected={equipment === "48' SD Con" ? true : false}
              >
                48' SD Con
              </option>
              <option
                name="equipment"
                value="40' hotshot"
                selected={equipment === "40' hotshot" ? true : false}
              >
                40' hotshot
              </option>
              <option
                name="equipment"
                value="30' hotshot"
                selected={equipment === "30' hotshot" ? true : false}
              >
                30' hotshot
              </option>
              <option
                name="equipment"
                value="26' box truck"
                selected={equipment === "26' box truck" ? true : false}
              >
                26' box truck
              </option>
              <option
                name="equipment"
                value="20' hotshot"
                selected={equipment === "20' hotshot" ? true : false}
              >
                20' hotshot
              </option>

              <option
                name="equipment"
                value="VR"
                selected={equipment === "VR" ? true : false}
              >
                VR
              </option>
              <option
                name="equipment"
                value="PO"
                selected={equipment === "PO" ? true : false}
              >
                PO
              </option>
              <option
                name="equipment"
                value="RGN"
                selected={equipment === "RGN" ? true : false}
              >
                RGN
              </option>
              <option
                name="equipment"
                value="DD"
                selected={equipment === "DD" ? true : false}
              >
                DD
              </option>
            </select>
          </div>
          <div>
            <label>Commodity</label>
            <input
              type="text"
              name="commodity"
              value={commodity}
              onChange={onChangeCaps}
            />
          </div>
          <div>
            <label>Weight</label>
            <input
              type="text"
              name="weight"
              value={weight}
              onChange={onChangeCaps}
            />
          </div>
          <div>
            <label>Tarp</label>
            <input
              type="text"
              name="tarp"
              value={tarp}
              onChange={onChangeCaps}
            />
          </div>
          <div>
            <label>Dims</label>
            <input
              type="text"
              name="dims"
              value={dims}
              onChange={onChangeCaps}
            />
          </div>
        </div>
        <div className="inputRow4 inputRow">
          {adminObj ? (
            adminObj.guest ? null : (
              <div>
                <label>Great Wide Rate</label>
                <input
                  type="number"
                  name="gwrate"
                  value={gwrate}
                  onChange={onChange}
                />
              </div>
            )
          ) : null}
          <div className="truckRateInputBigDiv">
            <div className="truckRateInput">
              <label>Truck Rate</label>
              <input
                type="number"
                name="truckrate"
                value={truckrate}
                onChange={onChange}
              />
            </div>
            <div className="truckRateInput" style={{ width: "30%" }}>
              <label style={{ marginLeft: "2vw" }}>Flexible?</label>
              <div
                onClick={changeRateFlex}
                className={rateFlex ? "flexOn" : "flex"}
              >
                <i className="fas fa-check checkIconPopUp"></i>
              </div>
            </div>
          </div>
        </div>
        <div className={open ? "singleOpenLoadNone" : "inputRow6 inputRow"}>
          <div>
            <label>Assigned to</label>
            <input type="text" name="admin" value={admin} disabled />
          </div>
          <div>
            <label>Delivered Date</label>
            <input type="text" name="delivered" value={delivered} disabled />
          </div>
          <div>
            <label>Paid Date</label>
            <input type="text" name="paidDate" value={paidDate} disabled />
          </div>
        </div>
        <div className={!nearlyComplete ? "singleOpenLoadNone" : "scannedRow"}>
          {scanned !== "NA" ? (
            <div className="scannedDivContainer">
              <div className="scannedDiv">
                <label>Scanned on</label>
                <input
                  type="text"
                  name="scannedDate"
                  value={scanned}
                  disabled
                />
              </div>
            </div>
          ) : (
            <p onClick={() => props.markScanned({ _id: load._id })}>
              Mark scanned
            </p>
          )}
        </div>
        <div className="inputRow5 inputRow">
          <div>
            <label>Notes</label>
            <textarea
              type="text"
              name="notes"
              value={notes}
              onChange={onChange}
            />
          </div>
        </div>
        {open ? (
          !label || label === "No label" ? (
            <div className="labelDivContainer">
              <p className="labelsLabel">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M472.8 168.4C525.1 221.4 525.1 306.6 472.8 359.6L360.8 472.9C351.5 482.3 336.3 482.4 326.9 473.1C317.4 463.8 317.4 448.6 326.7 439.1L438.6 325.9C472.5 291.6 472.5 236.4 438.6 202.1L310.9 72.87C301.5 63.44 301.6 48.25 311.1 38.93C320.5 29.61 335.7 29.7 344.1 39.13L472.8 168.4zM.0003 229.5V80C.0003 53.49 21.49 32 48 32H197.5C214.5 32 230.7 38.74 242.7 50.75L410.7 218.7C435.7 243.7 435.7 284.3 410.7 309.3L277.3 442.7C252.3 467.7 211.7 467.7 186.7 442.7L18.75 274.7C6.743 262.7 0 246.5 0 229.5L.0003 229.5zM112 112C94.33 112 80 126.3 80 144C80 161.7 94.33 176 112 176C129.7 176 144 161.7 144 144C144 126.3 129.7 112 112 112z" />
                </svg>
                Labels
              </p>
              <div className="labelDiv">
                <div
                  onClick={() => (
                    addLabel(load._id, "In Progress"), props.toggle(9)
                  )}
                  className="labelBtnDiv"
                  style={{ color: "#fff", backgroundColor: "#4271ff" }}
                >
                  In Progress
                </div>
                <div
                  onClick={() => (addLabel(load._id, "Hot"), props.toggle(9))}
                  className="labelBtnDiv"
                  style={{ color: "#fff", backgroundColor: "#BF4949" }}
                >
                  Hot
                </div>
                <div
                  onClick={() => (
                    addLabel(load._id, "On hold"), props.toggle(9)
                  )}
                  className="labelBtnDiv"
                  style={{ color: "#fff", backgroundColor: "#808080" }}
                >
                  On hold
                </div>
                <div
                  onClick={() => (
                    addLabel(load._id, "Not owned"), props.toggle(9)
                  )}
                  className="labelBtnDiv"
                  style={{ color: "#fff", backgroundColor: "#000" }}
                >
                  Not owned
                </div>
                <div
                  onClick={() => (props.toggle(21), props.toggle(9))}
                  className="labelBtnDiv"
                >
                  + add custom
                </div>
              </div>
            </div>
          ) : (
            <div className="labelDivContainer">
              <p className="labelsLabel">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M472.8 168.4C525.1 221.4 525.1 306.6 472.8 359.6L360.8 472.9C351.5 482.3 336.3 482.4 326.9 473.1C317.4 463.8 317.4 448.6 326.7 439.1L438.6 325.9C472.5 291.6 472.5 236.4 438.6 202.1L310.9 72.87C301.5 63.44 301.6 48.25 311.1 38.93C320.5 29.61 335.7 29.7 344.1 39.13L472.8 168.4zM.0003 229.5V80C.0003 53.49 21.49 32 48 32H197.5C214.5 32 230.7 38.74 242.7 50.75L410.7 218.7C435.7 243.7 435.7 284.3 410.7 309.3L277.3 442.7C252.3 467.7 211.7 467.7 186.7 442.7L18.75 274.7C6.743 262.7 0 246.5 0 229.5L.0003 229.5zM112 112C94.33 112 80 126.3 80 144C80 161.7 94.33 176 112 176C129.7 176 144 161.7 144 144C144 126.3 129.7 112 112 112z" />
                </svg>
                Label
              </p>
              <div className="labelDiv">
                {label === "In Progress" ? (
                  <div
                    className="labelBtnDiv"
                    style={{ color: "#fff", backgroundColor: "#4271ff" }}
                  >
                    In Progress
                  </div>
                ) : label === "Hot" ? (
                  <div
                    className="labelBtnDiv"
                    style={{ color: "#fff", backgroundColor: "#BF4949" }}
                  >
                    Hot
                  </div>
                ) : label === "On hold" ? (
                  <div
                    className="labelBtnDiv"
                    style={{ color: "#fff", backgroundColor: "#808080" }}
                  >
                    On hold
                  </div>
                ) : label === "Not owned" ? (
                  <div
                    className="labelBtnDiv"
                    style={{ color: "#fff", backgroundColor: "#000" }}
                  >
                    Not owned
                  </div>
                ) : label !== "" ? (
                  <div
                    className="customLabelBtnDiv"
                    style={{ color: "#fff", backgroundColor: "#BBC356" }}
                  >
                    {label}
                  </div>
                ) : null}
                <div
                  onClick={() => (addLabel(load._id, ""), props.toggle(9))}
                  className="removeLabelBtnDiv"
                  style={{
                    color: "#BF4949",
                    fontSize: "12px",
                    fontWeight: "500",
                    cursor: "pointer"
                  }}
                >
                  remove label
                </div>
              </div>
            </div>
          )
        ) : null}
        {master === true && _admin.name === "Jesse" ? (
          <div className="btnRow">
            <div
              onClick={() => (props.remove(load._id), props.toggle(9))}
              className="delBtn"
            >
              Remove
            </div>
            <div className="btnRowFiller"></div>
            <div
              onClick={() => (props.toggle(14), props.toggle(9))}
              className="copyMasterBtn"
            >
              Copy for Open Load
            </div>
            <div onClick={() => props.toggle(9)} className="cancelBtn">
              Exit
            </div>
            <div onClick={onPost} className="postBtn">
              Save
            </div>
          </div>
        ) : master === true ||
          (adminObj ? (adminObj.guest ? true : false) : true) ? (
          <div className="btnRow">
            <div className="btnRowFiller"></div>
            <div
              onClick={() => (props.toggle(14), props.toggle(9))}
              className="copyMasterBtn"
            >
              Copy for Open Load
            </div>
            <div onClick={() => props.toggle(9)} className="cancelBtn">
              Exit
            </div>
          </div>
        ) : open ? (
          <div className="btnRow">
            <div
              onClick={() => (props.remove(load._id), props.toggle(9))}
              className="delBtn"
            >
              Remove
            </div>
            <div className="btnRowFiller"></div>
            <div onClick={() => props.toggle(9)} className="cancelBtn">
              Exit
            </div>
            <div onClick={onPost} className="postBtn">
              Save
            </div>
          </div>
        ) : (
          <div className="btnRow">
            <div
              onClick={() => (props.remove(load._id), props.toggle(9))}
              className="delBtn"
            >
              Remove
            </div>
            <div className="btnRowFiller"></div>
            <div onClick={() => props.toggle(9)} className="cancelBtn">
              Exit
            </div>
            <div onClick={onPost} className="postBtn">
              Save
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleLoadPopUp;
