import React, { useState, useEffect } from "react";

const AddCommentPopUp = props => {
  const [feedback, setFeedback] = useState({
    notes: ""
  });

  const { notes } = feedback;

  useEffect(() => {
    //Erases state when toggling pop up
    setFeedback({
      ...feedback,
      notes: ""
    });
  }, []);

  const onChange = e =>
    setFeedback({ ...feedback, [e.target.name]: e.target.value });

  const onSubmit = () => {
    if (props.carrier === null) {
      alert("Error, please refresh page");
    } else {
      props.submitComment(feedback, props.carrier);
      props.toggle(17);
    }
  };

  return (
    <div className="fixedPopUp">
      <div className="carrierCommmentPopUp">
        <div className="notesDivCarrier">
          <h1>Add comment</h1>
          <textarea
            onChange={onChange}
            type="text"
            name="notes"
            value={notes}
          />
        </div>
        <div className="btnRow2">
          <div onClick={() => props.toggle(17)} className="cancelBtn">
            Cancel
          </div>
          <div
            style={{ marginLeft: "1vw" }}
            onClick={onSubmit}
            className="postBtn"
          >
            Post
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCommentPopUp;
