import React, { useState, useContext, useEffect } from "react";
import "../cod/styles/auth.css";
import { Link } from "react-router-dom";
import AuthContext from "../context/auth/authContext";
import Spinner from "../utilities/Spinner";

const Login = props => {
  const authContext = useContext(AuthContext);
  const { loginUser, error, loading, admin } = authContext;

  useEffect(() => {
    if (admin) {
      props.history.push(`/dashboard`);
    }
    // eslint-disable-next-line
  }, [error, admin]);

  const [user, setUser] = useState({
    email: "",
    password: "1234567"
  });

  const { email, password } = user;

  const onChange = e => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const onSubmit = e => {
    e.preventDefault();
    if (email === "" || password === "") {
      alert("Please fill in all forms");
    } else {
      loginUser({
        email,
        password
      });
    }
  };

  return (
    <div style={{backgroundColor: "#DCCCBB"}} className="blubackCont">
      {loading ? <Spinner /> : null}
      <div style={{backgroundColor: "#DCCCBB"}} className="loginShape6"></div>
      <div style={{backgroundColor: "#DCCCBB"}} className="loginShape8"></div>
      <div style={{backgroundColor: "#DCCCBB"}} className="loginShape9"></div>
      <div className="authCont">
        <div className="authMiniCont">
          <h1 className="h1CreateAccount">
            Guest <span style={{ fontWeight: "300" }}>Portal</span>
          </h1>
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="email">Email address</label>
              <input
                required
                type="email"
                name="email"
                value={email}
                onChange={onChange}
              />
            </div>
            <input type="submit" value="Sign in" className="fg5 loginFormBTN" />
          </form>
        </div>
      </div>
      <div className="bottomTextP">
        <p style={{color: "#333", fontWeight: "500"}}>
          Don't have a guest account? Create one{" "}
          <Link className="hereTextP" to={"/create/guest"}>
            here.
          </Link>
        </p>
        <p style={{color: "#333", fontWeight: "500"}}>
          Switch to Admin{" "}
          <Link className="hereTextP" to={"/"}>
            here.
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
