import React, { useContext } from "react";
import LoadContext from "../../context/loads/loadContext";

const CarrierWarning = props => {
  const loadContext = useContext(LoadContext);
  const { message, markLoadBooked2, carrierInfo, togglePopUp } = loadContext;

  return (
    <div className="fixedPopUp">
      <div className="carrierWarnPopUp">
        <h1>Use this carrier with caution</h1>
        <p>
          This carrier was previously rated a{" "}
          {message === "warning" ? `${2}` : `${1}`}
        </p>
        <div className="btnRow">
          <div className="btnRowFiller"></div>
          <div onClick={() => togglePopUp(11)} className="cancelBtn">
            Exit
          </div>
          <div onClick={() => markLoadBooked2(carrierInfo)} className="postBtn">
            Use Carrier
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarrierWarning;
