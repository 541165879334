import React, { useState, useEffect } from "react";

const SingleCarrierPopUp = props => {
  const [carrierState, setCarrierState] = useState({
    id: props.carrier._id,
    name: props.carrier.name,
    mc: props.carrier.mc,
    rating: props.carrier.rating,
    dispatchers: props.carrier.dispatchers,
    dispatcher_name: "",
    dispatcher_email: "",
    dispatcher_phone: ""
  });

  const {
    name,
    mc,
    rating,
    dispatchers,
    dispatcher_name,
    dispatcher_email,
    dispatcher_phone
  } = carrierState;

  const onChange = e =>
    setCarrierState({ ...carrierState, [e.target.name]: e.target.value });

  const onPost = () => {
    if (name === "") {
      alert("Must include carrier name");
    } else if (rating < 0 || rating > 5) {
      alert("Rating must be between 1-5");
    } else {
      props.edit(carrierState);
      props.toggle(20);
    }
  };

  const increaseDisp = () => {
    if (
      dispatcher_email === "" ||
      dispatcher_name === "" ||
      dispatcher_phone === ""
    ) {
      alert("All dispatcher fields must be enetered to add a dispatcher");
    } else {
      let newDisp = {
        name: dispatcher_name,
        email: dispatcher_email,
        phone: dispatcher_phone
      };
      
      let arrCopy = dispatchers;

      setCarrierState({
        ...carrierState,
        dispatcher_name: "",
        dispatcher_email: "",
        dispatcher_phone: "",
        dispatchers: [...arrCopy, newDisp]
      });
    }
  };

  const removeDispatcher = id => {
    setCarrierState({
      ...carrierState,
      dispatchers: carrierState.dispatchers.filter(x => x._id !== id)
    });
  };

  return (
    <div className="fixedPopUp">
      <div className="editCarrierPopUp">
        <div className="newInputRowCarrier inputRow">
          <div>
            <label>Carrier Name</label>
            <input type="text" name="name" value={name} onChange={onChange} />
          </div>
          <div>
            <label>MC</label>
            <input type="text" name="mc" value={mc} onChange={onChange} />
          </div>
          <div>
            <label>Rating</label>
            <input
              type="number"
              name="rating"
              value={rating}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="actualDispatchContainerCarrierPopUp">
          <div className="leftSideOfDispatchDiv">
            <p>Dispatchers</p>
            {dispatchers.map((x, i) => {
              return (
                <div key={i} className="actualDispatchDivCarrierPopUp">
                  <div>{`${x.name} - ${x.email} - ${x.phone}`}</div>
                  <div>
                    <i
                      onClick={() => removeDispatcher(x._id)}
                      className="far fa-times-circle"
                    ></i>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="rightSideOfDispatchDiv">
            <div className="inputFlexDivDispatchers">
              <div>
                <label>Name</label>
                <input
                  type="text"
                  name="dispatcher_name"
                  value={dispatcher_name}
                  onChange={onChange}
                />
              </div>
              <div>
                <label>Email</label>
                <input
                  type="text"
                  name="dispatcher_email"
                  value={dispatcher_email}
                  onChange={onChange}
                />
              </div>
              <div>
                <label>Phone</label>
                <input
                  type="text"
                  name="dispatcher_phone"
                  value={dispatcher_phone}
                  onChange={onChange}
                />
              </div>
            </div>
            <h6 onClick={increaseDisp}>Add dispatcher</h6>
          </div>
        </div>
        <div className="btnRow">
          <div
            onClick={() => (
              props.deleteCarrier(props.carrier._id), props.toggle(20)
            )}
            className="delBtn"
          >
            Remove
          </div>
          <div className="btnRowFiller2"></div>
          <div onClick={() => props.toggle(20)} className="cancelBtn">
            Cancel
          </div>
          <div onClick={onPost} className="postBtn">
            Save
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleCarrierPopUp;
