// Auth
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const OWNERLOGIN_SUCCESS = 'OWNERLOGIN_SUCCESS';
export const OWNERREGISTER_SUCCESS = 'OWNERREGISTER_SUCCESS';
export const OWNER_LOADED = 'OWNER_LOADED';
// End Auth

// Schedule
export const CHANGE_INDEX = 'CHANGE_INDEX';
export const CHANGE_SCHED = 'CHANGE_SCHED';
export const CHANGE_WEEK = 'CHANGE_WEEK';
export const TOGGLE_ADD_SHIFT = 'TOGGLE_ADD_SHIFT';
export const ADD_SHIFT = 'ADD_SHIFT';
export const ADD_MULTIPLE_SHIFTS = 'ADD_MULTIPLE_SHIFTS';
export const DELETE_SHIFT = 'DELETE_SHIFT';
export const TOGGLE_SHIFT = 'TOGGLE_SHIFT';
export const TOGGLE_ADD_SCHED = 'TOGGLE_ADD_SCHED';
export const TOGGLE_ADD_MESSAGE = 'TOGGLE_ADD_MESSAGE';
export const TOGGLE_SINGLE_MESSAGE = 'TOGGLE_SINGLE_MESSAGE';
export const TOGGLE_EDIT_SCHED = 'TOGGLE_EDIT_SCHED';
export const ADD_SCHED = 'ADD_SCHED';
export const GET_SCHED = 'GET_SCHED';
export const DELETE_SCHED = 'DELETE_SCHED';
export const EDIT_SCHED = 'EDIT_SCHED';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const GET_MESSAGES = 'GET_MESSAGES';
export const GET_REQUESTS = 'GET_REQUESTS';
export const CHANGE_REQUESTS = 'CHANGE_REQUESTS';
export const SET_SINGLE_MESSAGE = 'SET_SINGLE_MESSAGE';
export const GET_SHIFTS = 'GET_SHIFTS';
export const GET_TO_FIX = 'GET_TO_FIX';
export const REMOVE_TO_FIX = 'REMOVE_TO_FIX';
export const GET_REQUESTS_OFF = 'GET_REQUESTS_OFF';
export const SET_SINGLE_REQUEST_OFF = 'SET_SINGLE_REQUEST_OFF';
export const GET_WEEK = 'GET_WEEK';
export const CHANGE_SINGLE_SHIFT = 'CHANGE_SINGLE_SHIFT';
export const CANCEL_POPUP = 'CANCEL_POPUP';
export const ABSENT_POPUP = 'ABSENT_POPUP';
export const MARKED_ABSENT = 'MARKED_ABSENT';
export const TOGGLE_EDIT_HOURS = 'TOGGLE_EDIT_HOURS';
// End Schedule

// Staff
export const ADD_STAFF = 'ADD_STAFF';
export const EDIT_STAFF = 'EDIT_STAFF';
export const DELETE_STAFF = 'DELETE_STAFF';
export const GET_STAFF = 'GET_STAFF';
export const TOGGLE_ADD_STAFF = 'TOGGLE_ADD_STAFF';
export const TOGGLE_EDIT_STAFF = 'TOGGLE_EDIT_STAFF';
export const TOGGLE_SEARCH_HOURS = 'TOGGLE_SEARCH_HOURS';
export const GET_SEARCH_HOURS = 'GET_SEARCH_HOURS';
export const TOGGLE_POP_UP = 'TOGGLE_POP_UP';
export const TOGGLE_PRINT = 'TOGGLE_PRINT';
export const SET_SINGLE = 'SET_SINGLE';
export const SENT_CONTACT = 'SENT_CONTACT';
// End Staff

// Log
export const GOT_LOG = 'GOT_LOG';
export const GOT_LOG2 = 'GOT_LOG2';
export const CREATED_LOG = 'CREATED_LOG';
export const CREATED_REMINDER = 'CREATED_REMINDER';
export const GOT_REMINDERS = 'GOT_REMINDERS';
export const REMINDER_POPUP = 'REMINDER_POPUP';
export const DELETE_REMINDER = 'DELETE_REMINDER';
// End Log

// Account
export const GET_ADMINS = 'GET_ADMINS';
export const CREATE_ADMIN = 'CREATE_ADMIN';
export const SET_PRICE = 'SET_PRICE';
export const SET_SUB = 'SET_SUB';
export const CHANGE_PREF = 'CHANGE_PREF';
export const PURCHASE_SUCCESS = 'PURCHASE_SUCCESS';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const REQUEST_RESET = 'REQUEST_RESET';
export const TOGGLE_PASSWORD_POPS = 'TOGGLE_PASSWORD_POPS';
// End Account

export const PRINT = 'PRINT';

export const LOADING = 'LOADING';
export const LOADING_SCHED = 'LOADING_SCHED';
export const END_LOADING = 'END_LOADING';

export const LEAD_GEN_POP = 'LEAD_GEN_POP';
export const LEAD_GEN_SUCCESS_POP = 'LEAD_GEN_SUCCESS_POP';

export const TOGGLE_UPGRADE = 'TOGGLE_UPGRADE';
