import React from "react";
import Home from "./Home";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import history from "./utilities/history";
import "./cod/styles/App.css";
import PrivateRoute from "./routing/PrivateRoute";
import LoadState from "./context/loads/loadState";
import AuthState from "./context/auth/authState";
import setAuthToken from "./utilities/setAuthToken";
import Login from "./register/Login";
import LoginGuest from "./register/LoginGuest";
import RegisterGuest from "./register/RegisterGuest";
import Register from "./register/Register";

const App = () => {
  if (localStorage.admin_access) {
    setAuthToken(localStorage.admin_access);
  }

  return (
    <AuthState>
      <LoadState>
        <Router history={history}>
            <Switch>
              <Route path="/" exact component={Login} />
              <Route path="/login/guest" exact component={LoginGuest} />
              <Route path="/create" exact component={Register} />
              <Route path="/create/guest" exact component={RegisterGuest} />
              <PrivateRoute path="/dashboard" exact component={Home} />
            </Switch>
        </Router>
      </LoadState>
    </AuthState>
  );
};

export default App;
