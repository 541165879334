import React, { useState, useContext, useEffect } from "react";
import LoadContext from "../../context/loads/loadContext";
import DatePicker from "react-date-picker";

const CalculatePopUp = props => {
  const loadContext = useContext(LoadContext);
  const { getCalc, calcs, togglePopUp } = loadContext;

  const [calc, setCalc] = useState({
    date1: new Date(),
    date2: new Date(),
    date2cap: new Date(),
    searchable: false,
    date1border: false,
    date2border: false
  });

  const { date1, date2, date2cap, searchable, date2border, date1border } = calc;

  const handleDate1 = e =>
    setCalc({ ...calc, date1: e, searchable: true, date1border: true });

  const handleDate2 = e => setCalc({ ...calc, date2: e, date2border: true });

  const onSubmit = e => {
    e.preventDefault();
    if (!searchable) {
      alert("Must select first date in order to search.");
    } else {
      getCalc(date1, date2);
    }
  };

  const addedDays = () => {
    let date = new Date(date1);
    date.setDate(date.getDate() + 400);
    setCalc({ ...calc, date2cap: date });
  };
  useEffect(() => {
    addedDays();
  }, [date1]);

  return (
    <div className="absPopUp">
      <div className="CalculatePopUp">
        <div className="cancelNPrintDiv">
          <i onClick={() => togglePopUp(12)} className="fas fa-times"></i>
        </div>
        <h1 className="searchH1">Search work history</h1>
        <div className="searchDatesDiv">
          <div
            className="datePickerDivSearchHours"
            style={date1border ? { border: "2px solid #61e786" } : null}
          >
            <DatePicker
              value={date1}
              onChange={handleDate1}
              clearIcon={null}
              calendarIcon={null}
              className="xxDatePicker"
            />
          </div>
          <div className="datePickerArrowSearchHours">
            <i className="fas fa-caret-right"></i>
          </div>
          <div
            className="datePickerDivSearchHours"
            style={date2border ? { border: "2px solid #61e786" } : null}
          >
            <DatePicker
              value={date2}
              onChange={handleDate2}
              clearIcon={null}
              calendarIcon={null}
              className="SearchHoursDatePicker"
              minDate={date1}
              maxDate={date2cap}
            />
          </div>
          <div onClick={onSubmit} className="searchBtnDivSearchHours">
            Search
          </div>
        </div>
        <div className="searchSummary">
          <div>{`Loads: ${calcs.loads.length}`}</div>
          <div>{`Revenue: $${calcs.gw_total}`}</div>
          <div>
            {`Profit: $${parseFloat(calcs.gw_total - calcs.tr_total).toFixed(
              2
            )}`}
          </div>
        </div>
        <div className="loadHistory">
          {calcs.loads.length === 0 ? (
            <h1 className="searchH1">0 loads</h1>
          ) : (
            calcs.loads.map((x, i) => {
              return (
                <div className="singleSearchedLoad" key={i}>
                  <div>
                    {x.booked_date === "NA"
                      ? `PU Date: ${x.pu_date}`
                      : `Booked: ${x.booked_date}`}
                  </div>
                  <div>{`G: ${x.g}`}</div>
                  <div>{`GW Rate: ${x.gw_rate}`}</div>
                  <div>{`Truck Rate: ${x.truck_rate}`}</div>
                  <div>{`Assigned to ${x.booked_by}`}</div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default CalculatePopUp;
