export const CHANGE_INDEX = 'CHANGE_INDEX';
export const LOADING = 'LOADING';
export const TOGGLE_POPUP = 'TOGGLE_POPUP';
export const TOGGLE_REMOVE_POPUP = 'TOGGLE_REMOVE_POPUP';
export const TOGGLE_MARK_BOOKED_POPUP = 'TOGGLE_MARK_BOOKED_POPUP';
export const ADD_OPEN_LOAD = 'ADD_OPEN_LOAD';
export const ADD_QUOTE = 'ADD_QUOTE';
export const GET_OPEN_LOADS = 'GET_OPEN_LOADS';
export const GET_QUOTE_LOADS = 'GET_QUOTE_LOADS';
export const DELETE_OPEN_LOAD = 'DELETE_OPEN_LOAD';
export const GET_CURRENT_LOADS = 'GET_CURRENT_LOADS';
export const GET_NC_LOADS = 'GET_NC_LOADS';
export const DELETE_CURRENT_LOAD = 'DELETE_CURRENT_LOAD';
export const ADD_CURRENT_LOAD = 'ADD_CURRENT_LOAD';
export const GET_CARRIERS = 'GET_CARRIERS';
export const CHANGE_SINGLE_LOAD = 'CHANGE_SINGLE_LOAD';
export const CHANGE_SINGLE_CARRIER = 'CHANGE_SINGLE_CARRIER';
export const AFTER_FEEDBACK = 'AFTER_FEEDBACK';
export const GET_MASTER_LOADS = 'GET_MASTER_LOADS';
export const GET_MASTER_LOADS_NY = 'GET_MASTER_LOADS_NY';
export const GET_STAFF = 'GET_STAFF';
export const CLEAR = 'CLEAR';
export const SET_FILTER = 'SET_FILTER';
export const SET_FILTER_MC = 'SET_FILTER_MC';
export const WARNING = 'WARNING';
export const SET_CARRIER = 'SET_CARRIER';
export const GET_SCANNED_CSV = 'GET_SCANNED_CSV';
export const GET_CALC = 'GET_CALC';
export const GET_LANES = 'GET_LANES';