import React from "react";

const DeleteCarrierPopUp = props => {
  return (
    <div className="fixedPopUp">
      <div className="confirmDeliveryPopUp">
        <h1>Are you sure you want to remove this carrier?</h1>
        <div className="btnRow">
          <div className="btnRowFiller"></div>
          <div onClick={() => props.toggle(19)} className="cancelBtn">
            Cancel
          </div>
          <div
            onClick={() => (
              props.deleteCarrier(props.carrier._id), props.toggle(19)
            )}
            className="postBtn"
          >
            Confirm
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteCarrierPopUp;
