import React, { useContext, useEffect } from "react";
import LoadContext from "../context/loads/loadContext";

const Staff = () => {
  const loadContext = useContext(LoadContext);
  const { getStaff, staffList, deleteStaff, afterFeedback } = loadContext;

  useEffect(() => {
    getStaff();
  }, [afterFeedback]);

  return (
    <div className="bigStaffDiv">
      {staffList.length === 0
        ? null
        : staffList.map((x, i) => {
            return (
              <div key={i} className="singleStaff">
                {x.guest ? (
                  <p className="nameStaff">
                    {x.name} <span>Guest</span>
                  </p>
                ) : (
                  <React.Fragment>
                    <p className="nameStaff">{x.name}</p>
                    <p className="secondAttStaff">{x.position}</p>
                  </React.Fragment>
                )}
                <p className="secondAttStaff">{x.email}</p>
                <p
                  className="thirdAttStaff"
                  style={x.guest ? { backgroundColor: "#0036d8", color: "#fff" } : null}
                >{`Last active ${x.last_logged_in}`}</p>
                <p
                  className="removeBtnStaff"
                  onClick={() => deleteStaff(x._id)}
                >
                  Remove
                </p>
              </div>
            );
          })}
    </div>
  );
};

export default Staff;
