import axios from 'axios';

const setAuthToken = admin_access => {
    if (admin_access) {
        axios.defaults.headers.common['x-auth-token'] = admin_access;
    } else {
        delete axios.defaults.headers.common['x-auth-token'];
    }
};


export default setAuthToken;