import React, { useState, useEffect } from "react";

const CarrierFeedbackPopUp = props => {
  const [feedback, setFeedback] = useState({
    rating: 4,
    notes: ""
  });

  const { rating, notes } = feedback;

  useEffect(() => {
    //Erases state when toggling pop up
    setFeedback({
      ...feedback,
      rating: 4,
      notes: ""
    });
  }, []);

  const onChange = e =>
    setFeedback({ ...feedback, [e.target.name]: e.target.value });

  const setRating = num => setFeedback({ ...feedback, rating: num });

  const onSubmit = () => {
    props.submitFeedback(feedback, props.load);
    props.toggle(7);
  };

  return (
    <div className="fixedPopUp">
      <div className="carrierPopUp">
        <h1>Carrier feedback</h1>
        <div className="infoCarrier">
          Please rate the carrier on
          <ul>
            <li>Accuracy of PU/DO time</li>
            <li>Attitude of driver</li>
            <li>Ability to answer & provide check calls</li>
          </ul>
        </div>
        <div className="dayRateDiv">
          <div
            className={
              rating === 1 ? "firstNumFill dayRate" : "firstNum dayRate"
            }
            onClick={() => setRating(1)}
          >
            1
          </div>
          <div
            className={rating === 2 ? "secNumFill dayRate" : "secNum dayRate"}
            onClick={() => setRating(2)}
          >
            2
          </div>
          <div
            className={
              rating === 3 ? "thirdNumFill dayRate" : "thirdNum dayRate"
            }
            onClick={() => setRating(3)}
          >
            3
          </div>
          <div
            className={
              rating === 4 ? "fourthNumFill dayRate" : "fourthNum dayRate"
            }
            onClick={() => setRating(4)}
          >
            4
          </div>
          <div
            className={
              rating === 5 ? "fifthNumFill dayRate" : "fifthNum dayRate"
            }
            onClick={() => setRating(5)}
          >
            5
          </div>
        </div>
        <div className="notesDivCarrier">
          <h1>Comments</h1>
          <textarea
            onChange={onChange}
            type="text"
            name="notes"
            value={notes}
          />
        </div>
        <div className="btnRow">
          <div onClick={() => props.revert(props.load._id)} className="revertBtn">
            Revert (keep as current load)
          </div>
          <div onClick={() => props.toggle(7)} className="cancelBtn">
            Skip
          </div>
          <div onClick={onSubmit} className="postBtn">
            Save
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarrierFeedbackPopUp;
