import React, { useState } from "react";

const CustomLabelPopUp = props => {
  const [customLabel, setCustomLabel] = useState("");

  const onChange = e => setCustomLabel(e.target.value);

  return (
    <div className="fixedPopUp">
      <div className="customLabelPopUp">
        <div>
          <h1>Custom label</h1>
          <div className="infoCustomLabel">Enter a custom label for load</div>
          <div className="inputCustomLabel">
            <input
              type="text"
              name="customLabel"
              value={customLabel}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="btnRow">
          <div onClick={() => props.toggle(21)} className="cancelBtn">
            Cancel
          </div>
          <div
            onClick={
              customLabel === ""
                ? null
                : () => (
                    props.addLabel(props.load._id, customLabel),
                    props.toggle(21)
                  )
            }
            className="postBtn"
          >
            Save
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomLabelPopUp;
