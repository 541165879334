import React from "react";

const ConfirmDeliveryPopUp = props => {
  return (
    <div className="fixedPopUp">
      <div className="confirmDeliveryPopUp">
        <h1>Confirm delivery of load</h1>
        <div className="btnRow">
          <div className="btnRowFiller"></div>
          <div onClick={() => props.toggle(6)} className="cancelBtn">
            Cancel
          </div>
          <div onClick={() => (props.markDelivered(props.load), props.toggle(7))} className="postBtn">
            Continue
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeliveryPopUp;
