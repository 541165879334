import React, { useContext, useEffect } from "react";
import LoadContext from "../context/loads/loadContext";
import RemoveLoadPopUp from "./pop-ups/RemoveLoadPopUp";
import MarkBookedPopUp from "./pop-ups/MarkBookedPopUp";
import SingleLoadPopUp from "./pop-ups/SingleLoadPopUp";
import CarrierWarning from "./pop-ups/CarrierWarning";
import DupLoadPopUp from "./pop-ups/DupLoadPopUp";
import AddQuotePopUp from "./pop-ups/AddQuotePopUp";
import SingleQuotePopUp from "./pop-ups/SingleQuotePopUp";
import FilterPopUp from "./pop-ups/FilterPopUp";

const Quotes = props => {
  const loadContext = useContext(LoadContext);
  const {
    addQuotePopUp,
    togglePopUp,
    addQuote,
    getQuoteLoads,
    quoteLoadList,
    afterDeleteOpen,
    toggleRemovePopUp,
    toggleMarkBookedPopUp,
    editQuote,
    singleQuotePopUp,
    singleLoad,
    submitFeedback,
    changeSingleLoad,
    afterDeleteCurrent,
    removeQuote,
    quote2Open,
    filterPopUp,
    filterList,
    clearFilter,
    filter1,
    filter2,
    filter3,
    filter4,
    filter1Value,
    filter2Value,
    filter3Value,
    filter4Value
  } = loadContext;

  useEffect(() => {
    getQuoteLoads();
  }, [afterDeleteOpen, afterDeleteCurrent]);

  return (
    <React.Fragment>
      {addQuotePopUp ? (
        <AddQuotePopUp quote={true} toggle={togglePopUp} add={addQuote} />
      ) : null}
      {singleQuotePopUp ? (
        <SingleQuotePopUp
          toggle={togglePopUp}
          submitFeedback={submitFeedback}
          add={editQuote}
          load={singleLoad}
          open={true}
          quote={true}
          master={false}
          _admin={{ name: "nope" }}
          remove={removeQuote}
          adminObj={props.adminObj}
        />
      ) : null}
      {filterPopUp ? (
        <FilterPopUp
          toggle={togglePopUp}
          filter={filterList}
          clear={clearFilter}
          source={"quote"}
        />
      ) : null}
      <div className="toolBarContent">
        {/* <div id="filter">
          <div>
            <i className="fas fa-filter searchIcon"></i>Filter
          </div>
        </div> */}
        {props.adminObj ? (
          props.adminObj.guest ? null : (
            <React.Fragment>
              <div onClick={() => togglePopUp(10)} id="filter">
                <div>
                  <i className="fas fa-filter searchIcon"></i>Filter
                </div>
              </div>

              <div id="addOpenLoad" onClick={() => togglePopUp(15)}>
                Add Quote
              </div>
            </React.Fragment>
          )
        ) : null}
      </div>
      <div className="bigCurrentDiv">
        {filter4 !== ""
          ? quoteLoadList
              .filter(
                x =>
                  x[filter1].toLowerCase() === filter1Value.toLowerCase() &&
                  x[filter2].toLowerCase() === filter2Value.toLowerCase() &&
                  x[filter3].toLowerCase() === filter3Value.toLowerCase() &&
                  x[filter4].toLowerCase() === filter4Value.toLowerCase()
              )
              .map((x, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => (changeSingleLoad(x), togglePopUp(16))}
                    className={x.stage === 1 ? "actualLoadQuoteStage2 actualOpenLoad" : "actualLoad actualOpenLoad"}
                  >
                    <div className="dateCreatedQuote">{`${x.date_created}`}</div>
                    <div className="titleActualLoad titleOpen">
                      {`${x.origin}, ${x.origin_state} - ${x.destination}, ${x.destination_state}`}
                    </div>
                    <div
                      className={
                        props.adminObj
                          ? props.adminObj.guest
                            ? "firstAttribute firstAttOpen2"
                            : "firstAttribute firstAttQuote"
                          : null
                      }
                    >
                      {`${x.customer}`}
                    </div>
                    <div className="secAttribute secOnOpen">
                      {`$${parseFloat(
                        x.truck_rate / x.new_google_miles
                      ).toFixed(2)}/mile `}
                    </div>
                    <div className="secAttribute secOnOpen">
                      {`$${x.truck_rate} `}
                    </div>
                    {props.adminObj ? (
                      props.adminObj.guest ? null : (
                        <div className="fourthAttribute fourthOnOpen">
                          <div
                            onClick={() => (quote2Open(x._id), togglePopUp(16))}
                            className="markBooked"
                          >
                            Push to open
                          </div>
                          <div
                            onClick={() => (
                              removeQuote(x._id), togglePopUp(16)
                            )}
                            className="markRemove"
                          >
                            Remove
                          </div>
                        </div>
                      )
                    ) : null}
                  </div>
                );
              })
          : filter3 !== ""
          ? quoteLoadList
              .filter(
                x =>
                  x[filter1].toLowerCase() === filter1Value.toLowerCase() &&
                  x[filter2].toLowerCase() === filter2Value.toLowerCase() &&
                  x[filter3].toLowerCase() === filter3Value.toLowerCase()
              )
              .map((x, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => (changeSingleLoad(x), togglePopUp(16))}
                    className={x.stage === 1 ? "actualLoadQuoteStage2 actualOpenLoad" : "actualLoad actualOpenLoad"}
                  >
                    <div className="dateCreatedQuote">{`${x.date_created}`}</div>
                    <div className="titleActualLoad titleOpen">
                      {`${x.origin}, ${x.origin_state} - ${x.destination}, ${x.destination_state}`}
                    </div>
                    <div
                      className={
                        props.adminObj
                          ? props.adminObj.guest
                            ? "firstAttribute firstAttOpen2"
                            : "firstAttribute firstAttQuote"
                          : null
                      }
                    >
                      {`${x.customer}`}
                    </div>
                    <div className="secAttribute secOnOpen">
                      {`$${parseFloat(
                        x.truck_rate / x.new_google_miles
                      ).toFixed(2)}/mile `}
                    </div>
                    <div className="secAttribute secOnOpen">
                      {`$${x.truck_rate} `}
                    </div>
                    {props.adminObj ? (
                      props.adminObj.guest ? null : (
                        <div className="fourthAttribute fourthOnOpen">
                          <div
                            onClick={() => (quote2Open(x._id), togglePopUp(16))}
                            className="markBooked"
                          >
                            Push to open
                          </div>
                          <div
                            onClick={() => (
                              removeQuote(x._id), togglePopUp(16)
                            )}
                            className="markRemove"
                          >
                            Remove
                          </div>
                        </div>
                      )
                    ) : null}
                  </div>
                );
              })
          : filter2 !== ""
          ? quoteLoadList
              .filter(
                x =>
                  x[filter1].toLowerCase() === filter1Value.toLowerCase() &&
                  x[filter2].toLowerCase() === filter2Value.toLowerCase()
              )
              .map((x, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => (changeSingleLoad(x), togglePopUp(16))}
                    className={x.stage === 1 ? "actualLoadQuoteStage2 actualOpenLoad" : "actualLoad actualOpenLoad"}
                  >
                    <div className="dateCreatedQuote">{`${x.date_created}`}</div>
                    <div className="titleActualLoad titleOpen">
                      {`${x.origin}, ${x.origin_state} - ${x.destination}, ${x.destination_state}`}
                    </div>
                    <div
                      className={
                        props.adminObj
                          ? props.adminObj.guest
                            ? "firstAttribute firstAttOpen2"
                            : "firstAttribute firstAttQuote"
                          : null
                      }
                    >
                      {`${x.customer}`}
                    </div>
                    <div className="secAttribute secOnOpen">
                      {`$${parseFloat(
                        x.truck_rate / x.new_google_miles
                      ).toFixed(2)}/mile `}
                    </div>
                    <div className="secAttribute secOnOpen">
                      {`$${x.truck_rate} `}
                    </div>
                    {props.adminObj ? (
                      props.adminObj.guest ? null : (
                        <div className="fourthAttribute fourthOnOpen">
                          <div
                            onClick={() => (quote2Open(x._id), togglePopUp(16))}
                            className="markBooked"
                          >
                            Push to open
                          </div>
                          <div
                            onClick={() => (
                              removeQuote(x._id), togglePopUp(16)
                            )}
                            className="markRemove"
                          >
                            Remove
                          </div>
                        </div>
                      )
                    ) : null}
                  </div>
                );
              })
          : filter1 !== ""
          ? quoteLoadList
              .filter(
                x => x[filter1].toLowerCase() === filter1Value.toLowerCase()
              )
              .map((x, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => (changeSingleLoad(x), togglePopUp(16))}
                    className={x.stage === 1 ? "actualLoadQuoteStage2 actualOpenLoad" : "actualLoad actualOpenLoad"}
                  >
                    <div className="dateCreatedQuote">{`${x.date_created}`}</div>
                    <div className="titleActualLoad titleOpen">
                      {`${x.origin}, ${x.origin_state} - ${x.destination}, ${x.destination_state}`}
                    </div>
                    <div
                      className={
                        props.adminObj
                          ? props.adminObj.guest
                            ? "firstAttribute firstAttOpen2"
                            : "firstAttribute firstAttQuote"
                          : null
                      }
                    >
                      {`${x.customer}`}
                    </div>
                    <div className="secAttribute secOnOpen">
                      {`$${parseFloat(
                        x.truck_rate / x.new_google_miles
                      ).toFixed(2)}/mile `}
                    </div>
                    <div className="secAttribute secOnOpen">
                      {`$${x.truck_rate} `}
                    </div>
                    {props.adminObj ? (
                      props.adminObj.guest ? null : (
                        <div className="fourthAttribute fourthOnOpen">
                          <div
                            onClick={() => (quote2Open(x._id), togglePopUp(16))}
                            className="markBooked"
                          >
                            Push to open
                          </div>
                          <div
                            onClick={() => (
                              removeQuote(x._id), togglePopUp(16)
                            )}
                            className="markRemove"
                          >
                            Remove
                          </div>
                        </div>
                      )
                    ) : null}
                  </div>
                );
              })
          : quoteLoadList.map((x, i) => {
              return (
                <div
                  key={i}
                  onClick={() => (changeSingleLoad(x), togglePopUp(16))}
                  className={x.stage === 1 ? "actualLoadQuoteStage2 actualOpenLoad" : "actualLoad actualOpenLoad"}
                >
                  <div className="dateCreatedQuote">{`${x.date_created}`}</div>
                  <div className="titleActualLoad titleOpen">
                    {`${x.origin}, ${x.origin_state} - ${x.destination}, ${x.destination_state}`}
                  </div>
                  <div
                    className={
                      props.adminObj
                        ? props.adminObj.guest
                          ? "firstAttribute firstAttOpen2"
                          : "firstAttribute firstAttQuote"
                        : null
                    }
                  >
                    {`${x.customer}`}
                  </div>
                  <div className="secAttribute secOnOpen">
                    <div>
                      {`$${parseFloat(x.gw_rate / x.new_google_miles).toFixed(
                        2
                      )}/mile - GW `}
                    </div>
                    <div>
                      {`$${parseFloat(
                        x.truck_rate / x.new_google_miles
                      ).toFixed(2)}/mile `}
                    </div>
                  </div>
                  <div className="secAttribute secOnOpen">
                    {`$${x.truck_rate} `}
                  </div>
                  {props.adminObj ? (
                    props.adminObj.guest ? null : (
                      <div className="fourthAttribute fourthOnOpen">
                        <div
                          onClick={() => (quote2Open(x._id), togglePopUp(16))}
                          className="markBooked"
                        >
                          Push to open
                        </div>
                        <div
                          onClick={() => (removeQuote(x._id), togglePopUp(16))}
                          className="markRemove"
                        >
                          Remove
                        </div>
                      </div>
                    )
                  ) : null}
                </div>
              );
            })}
      </div>
    </React.Fragment>
  );
};

export default Quotes;
