import React, { useReducer } from "react";
import axios from "axios";
import loadContext from "./loadContext";
import loadReducer from "./loadReducer";

import {
  LOADING,
  CHANGE_INDEX,
  TOGGLE_POPUP,
  ADD_OPEN_LOAD,
  ADD_QUOTE,
  GET_OPEN_LOADS,
  GET_QUOTE_LOADS,
  DELETE_OPEN_LOAD,
  TOGGLE_REMOVE_POPUP,
  TOGGLE_MARK_BOOKED_POPUP,
  GET_CURRENT_LOADS,
  GET_NC_LOADS,
  DELETE_CURRENT_LOAD,
  GET_CARRIERS,
  CHANGE_SINGLE_LOAD,
  AFTER_FEEDBACK,
  GET_MASTER_LOADS,
  GET_STAFF,
  CLEAR,
  SET_FILTER,
  SET_FILTER_MC,
  WARNING,
  SET_CARRIER,
  GET_SCANNED_CSV,
  GET_CALC,
  GET_LANES,
  CHANGE_SINGLE_CARRIER,
  GET_MASTER_LOADS_NY
} from "./types";

const LoadState = props => {
  const initialState = {
    loading: false,
    index: 1,
    singleLoadPopUp: false,
    singleQuotePopUp: false,
    addOpenLoadPopUp: false,
    addQuotePopUp: false,
    addCurrentLoadPopUp: false,
    addMasterLoadPopUp: false,
    addCarrierPopUp: false,
    deleteLoadPopUp: false,
    filterPopUp: false,
    calcPopUp: false,
    lanePopUp: false,
    dupPopUp: false,
    openLoadList: [],
    quoteLoadList: [],
    NCLoadList: [],
    currentLoadList: [],
    masterList: [],
    masterListNY: [],
    carrierList: [],
    staffList: [],
    scannedLoadsCSV: [],
    singleLoad: null,
    singleCarrier: null,
    singleLoadPopUp: false,
    singleLoadId: null,
    afterDeleteOpen: false,
    afterFeedback: false,
    afterDeleteCurrent: false,
    markLoadBookedPopUp: false,
    confirmDeliveryPopUp: false,
    deleteCarrierPopUp: false,
    finalizePopUp: false,
    carrierFeedbackPopUp: false,
    carrierWarningPopUp: false,
    addCommentPopUp: false,
    singleCarrierPopUp: false,
    customLabelPopUp: false,
    message: null,
    carrierInfo: null,
    // filters: {
    //   g: "",
    //   mc: "",
    //   carrier: "",
    //   customer: "",
    //   origin: "",
    //   destination: "",
    //   commodity: "",
    //   dims: "",
    //   equipment: "",
    //   tarp: "",
    //   weight: ""
    // },
    filterMC: "",
    filter1: "",
    filter2: "",
    filter3: "",
    filter4: "",
    filter1Value: "",
    filter2Value: "",
    filter3Value: "",
    filter4Value: "",
    calcs: {
      loads: [],
      gw_total: 0,
      tr_total: 0
    },
    lanes: []
  };

  const [state, dispatch] = useReducer(loadReducer, initialState);

  const changeIndex = num => {
    dispatch({
      type: CHANGE_INDEX,
      payload: num
    });
  };

  const changeSingleLoad = load => {
    dispatch({
      type: CHANGE_SINGLE_LOAD,
      payload: load
    });
  };

  const changeSingleCarrier = carr => {
    dispatch({
      type: CHANGE_SINGLE_CARRIER,
      payload: carr
    });
  };

  const togglePopUp = num => {
    dispatch({
      type: TOGGLE_POPUP,
      payload: num
    });
  };

  const toggleRemovePopUp = id => {
    dispatch({
      type: TOGGLE_REMOVE_POPUP,
      payload: id
    });
  };
  const toggleMarkBookedPopUp = id => {
    dispatch({
      type: TOGGLE_MARK_BOOKED_POPUP,
      payload: id
    });
  };

  //Edit Load
  const editLoad = async formData => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    try {
      dispatch({
        type: LOADING,
        payload: null
      });

      const res = await axios.post("/api/loads/edit", formData, config);

      dispatch({
        type: DELETE_CURRENT_LOAD,
        payload: !state.afterDeleteCurrent
      });
    } catch (err) {
      console.error(err);
    }
  };

  //Edit Quote
  const editQuote = async formData => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    try {
      dispatch({
        type: LOADING,
        payload: null
      });

      const res = await axios.post("/api/loads/quote-edit", formData, config);

      dispatch({
        type: DELETE_CURRENT_LOAD,
        payload: !state.afterDeleteCurrent
      });
    } catch (err) {
      console.error(err);
    }
  };
  //Create Check up
  const check = async formData => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    try {
      await axios.post("/api/loads/check-call", formData, config);
    } catch (err) {
      console.error(err);
    }
  };

  //Create current Load
  const addCurrentLoad = async formData => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    try {
      dispatch({
        type: LOADING,
        payload: null
      });

      const res = await axios.post("/api/loads/current", formData, config);

      dispatch({
        type: ADD_OPEN_LOAD,
        payload: res.data
      });
    } catch (err) {
      console.error(err);
    }
  };

  //Create Quote
  const addQuote = async formData => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    try {
      dispatch({
        type: LOADING,
        payload: null
      });

      const res = await axios.post("/api/loads/quotes", formData, config);

      dispatch({
        type: ADD_QUOTE,
        payload: res.data
      });
    } catch (err) {
      console.error(err);
    }
  };

  //Create Open Load
  const addOpenLoad = async formData => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    try {
      dispatch({
        type: LOADING,
        payload: null
      });

      const res = await axios.post("/api/loads/open", formData, config);

      dispatch({
        type: ADD_OPEN_LOAD,
        payload: res.data
      });
    } catch (err) {
      console.error(err);
    }
  };

  //Duplicate Open Load
  const dupOpenLoad = async formData => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    try {
      dispatch({
        type: LOADING,
        payload: null
      });

      const res = await axios.post(
        "/api/loads/open-duplicate",
        formData,
        config
      );

      dispatch({
        type: DELETE_OPEN_LOAD,
        payload: !state.afterDeleteOpen
      });
    } catch (err) {
      console.error(err);
    }
  };

  //GET Quote Loads
  const getQuoteLoads = async () => {
    try {
      dispatch({
        type: LOADING,
        payload: null
      });

      const res = await axios.get("/api/loads/quotes");

      dispatch({
        type: GET_QUOTE_LOADS,
        payload: res.data
      });
    } catch (err) {
      console.error(err);
    }
  };

  //GET Open Loads
  const getOpenLoads = async () => {
    try {
      dispatch({
        type: LOADING,
        payload: null
      });

      const res = await axios.get("/api/loads/open");

      dispatch({
        type: GET_OPEN_LOADS,
        payload: res.data
      });
    } catch (err) {
      console.error(err);
    }
  };

  //Remove Quote
  const removeQuote = async id => {
    try {
      dispatch({
        type: LOADING,
        payload: null
      });

      const res = await axios.delete(`/api/loads/quote-delete/${id}`);

      dispatch({
        type: DELETE_OPEN_LOAD,
        payload: !state.afterDeleteOpen
      });
    } catch (err) {
      console.error(err);
    }
  };

  //Remove Open Load
  const removeOpenLoad = async id => {
    try {
      dispatch({
        type: LOADING,
        payload: null
      });

      const res = await axios.delete(`/api/loads/open-delete/${id}`);

      dispatch({
        type: DELETE_OPEN_LOAD,
        payload: !state.afterDeleteOpen
      });
    } catch (err) {
      console.error(err);
    }
  };

  //Create Current Load w New Info
  const markLoadBooked = async formData => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    try {
      dispatch({
        type: LOADING,
        payload: null
      });
      dispatch({
        type: SET_CARRIER,
        payload: formData
      });

      const res = await axios.post(
        "/api/loads/open-to-booked",
        formData,
        config
      );

      if (res.data === "alert" || res.data === "warning") {
        dispatch({
          type: WARNING,
          payload: res.data
        });
      } else {
        dispatch({
          type: DELETE_OPEN_LOAD,
          payload: res.data
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  //Create Current Load w New Info -- After neglecting 1 star carrier
  const markLoadBooked2 = async formData => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    try {
      dispatch({
        type: LOADING,
        payload: null
      });

      const res = await axios.post(
        "/api/loads/open-to-booked2",
        formData,
        config
      );

      dispatch({
        type: DELETE_OPEN_LOAD,
        payload: !state.afterDeleteOpen
      });
    } catch (err) {
      console.error(err);
    }
  };

  //GET Master Loads
  const getMasterLoads = async () => {
    try {
      dispatch({
        type: LOADING,
        payload: null
      });

      const res = await axios.get("/api/loads/master");

      dispatch({
        type: GET_MASTER_LOADS,
        payload: res.data
      });
    } catch (err) {
      console.error(err);
    }
  };

  //GET Master Loads New Year
  const getMasterLoadsByYear = async (year1, year2) => {
    try {
      dispatch({
        type: LOADING,
        payload: null
      });

      const res = await axios.get(`/api/loads/master/${year1}/${year2}`);

      dispatch({
        type: GET_MASTER_LOADS_NY,
        payload: res.data
      });
    } catch (err) {
      console.error(err);
    }
  };

  //GET Nearly Complete Loads
  const getNCLoads = async () => {
    try {
      dispatch({
        type: LOADING,
        payload: null
      });

      const res = await axios.get("/api/loads/nc");

      dispatch({
        type: GET_NC_LOADS,
        payload: res.data
      });
    } catch (err) {
      console.error(err);
    }
  };

  //GET Current Loads
  const getCurrentLoads = async () => {
    try {
      dispatch({
        type: LOADING,
        payload: null
      });

      const res = await axios.get("/api/loads/current");

      dispatch({
        type: GET_CURRENT_LOADS,
        payload: res.data
      });
    } catch (err) {
      console.error(err);
    }
  };

  //Remove Current Load
  const removeCurrentLoad = async id => {
    try {
      dispatch({
        type: LOADING,
        payload: null
      });

      const res = await axios.delete(`/api/loads/current-delete/${id}`);

      dispatch({
        type: DELETE_CURRENT_LOAD,
        payload: !state.afterDeleteCurrent
      });
    } catch (err) {
      console.error(err);
    }
  };

  //Revert back to Current Load
  const revert = async id => {
    try {
      dispatch({
        type: LOADING,
        payload: null
      });

      const res = await axios.get(`/api/loads/revert/${id}`);

      dispatch({
        type: DELETE_CURRENT_LOAD,
        payload: !state.afterDeleteCurrent
      });
      dispatch({
        type: TOGGLE_POPUP,
        payload: 7
      });
    } catch (err) {
      console.error(err);
    }
  };

  //Get carriers
  const getCarriers = async id => {
    try {
      dispatch({
        type: LOADING,
        payload: null
      });

      const res = await axios.get(`/api/loads/carriers`);

      dispatch({
        type: GET_CARRIERS,
        payload: res.data
      });
    } catch (err) {
      console.error(err);
    }
  };

  //Mark Delivered
  const markDelivered = async formData => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    try {
      dispatch({
        type: LOADING,
        payload: null
      });

      const res = await axios.post(
        "/api/loads/booked-to-delivered",
        formData,
        config
      );

      dispatch({
        type: DELETE_CURRENT_LOAD,
        payload: !state.afterDeleteCurrent
      });
    } catch (err) {
      console.error(err);
    }
  };

  //Mark Finished
  const markFinished = async formData => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    try {
      dispatch({
        type: LOADING,
        payload: null
      });

      const res = await axios.post(
        "/api/loads/delivered-to-finish",
        formData,
        config
      );

      dispatch({
        type: DELETE_CURRENT_LOAD,
        payload: !state.afterDeleteCurrent
      });
    } catch (err) {
      console.error(err);
    }
  };

  //Mark Scanned
  const markScanned = async formData => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    try {
      dispatch({
        type: LOADING,
        payload: null
      });

      const res = await axios.post("/api/loads/mark-scanned", formData, config);

      dispatch({
        type: DELETE_CURRENT_LOAD,
        payload: !state.afterDeleteCurrent
      });
    } catch (err) {
      console.error(err);
    }
  };

  //Add a Label to load
  const addLabel = async (id, str) => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const formData = {};
    formData.loadID = id;
    formData.loadLabel = str;

    try {
      dispatch({
        type: LOADING,
        payload: null
      });

      const res = await axios.post("/api/loads/add-label", formData, config);

      dispatch({
        type: DELETE_CURRENT_LOAD,
        payload: !state.afterDeleteCurrent
      });
    } catch (err) {
      console.error(err);
    }
  };
  const getScansCSV = async () => {
    try {
      const resCSV = await axios.get(
        `/api/loads/scanned-loads-csv`
      );

      dispatch({
        type: GET_SCANNED_CSV,
        csv: resCSV.data
      });
    } catch (err) {
      console.error(err);
    }
  };
  //Submit carrier feedback
  const submitFeedback = async (feedback, load) => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    let formData = {};
    formData.mc = load.mc;
    formData.loadID = load._id;
    formData.rating = feedback.rating;
    formData.notes = feedback.notes;

    try {
      dispatch({
        type: LOADING,
        payload: null
      });

      const res = await axios.post(
        "/api/loads/carrier-feedback",
        formData,
        config
      );

      dispatch({
        type: AFTER_FEEDBACK,
        payload: !state.afterFeedback
      });
    } catch (err) {
      console.error(err);
    }
  };

  // Add Carrier
  const addCarrier = async carrier => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    let formData = {};
    formData.carrier = carrier;

    try {
      dispatch({
        type: LOADING,
        payload: null
      });

      const res = await axios.post("/api/loads/carrier", formData, config);

      dispatch({
        type: AFTER_FEEDBACK,
        payload: !state.afterFeedback
      });
    } catch (err) {
      console.error(err);
    }
  };

  // Edit Carrier
  const editCarrier = async carrier => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    let formData = {};
    formData.carrier = carrier;

    try {
      dispatch({
        type: LOADING,
        payload: null
      });

      const res = await axios.post("/api/loads/carrier-edit", formData, config);

      dispatch({
        type: AFTER_FEEDBACK,
        payload: !state.afterFeedback
      });
    } catch (err) {
      console.error(err);
    }
  };

  //Submit carrier comment
  const submitComment = async (feedback, carrier) => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    let formData = {};
    formData.carrier = carrier;
    formData.notes = feedback.notes;

    try {
      dispatch({
        type: LOADING,
        payload: null
      });

      const res = await axios.post(
        "/api/loads/carrier-comments",
        formData,
        config
      );

      dispatch({
        type: AFTER_FEEDBACK,
        payload: !state.afterFeedback
      });
    } catch (err) {
      console.error(err);
    }
  };

  //Delete Carrier
  const deleteCarrier = async id => {
    try {
      dispatch({
        type: LOADING,
        payload: null
      });

      const res = await axios.delete(`/api/loads/carrier/${id}`);

      dispatch({
        type: AFTER_FEEDBACK,
        payload: !state.afterFeedback
      });
    } catch (err) {
      console.error(err);
    }
  };

  //Delete Staff
  const deleteStaff = async id => {
    try {
      dispatch({
        type: LOADING,
        payload: null
      });

      const res = await axios.delete(`/api/loads/staff/${id}`);

      if (res.data === "nope") {
        alert("Not authorized");
      }

      dispatch({
        type: AFTER_FEEDBACK,
        payload: !state.afterFeedback
      });
    } catch (err) {
      console.error(err);
    }
  };

  //get staff
  const getStaff = async () => {
    try {
      dispatch({
        type: LOADING,
        payload: null
      });

      const res = await axios.get(`/api/loads/staff`);

      dispatch({
        type: GET_STAFF,
        payload: res.data
      });
    } catch (err) {
      console.error(err);
    }
  };

  //Clear Filter
  const clearFilter = async () => {
    dispatch({
      type: CLEAR,
      payload: null
    });
  };

  //Filter
  const filterList = filt => {
    dispatch({
      type: SET_FILTER,
      payload: filt
    });
  };

  //Filter MC
  const filterByMC = filt => {
    dispatch({
      type: SET_FILTER_MC,
      payload: filt
    });
  };

  //Get calculations
  const getCalc = async (date1, date2) => {
    try {
      dispatch({
        type: LOADING,
        payload: null
      });

      const res = await axios.get(`/api/loads/load/history/${date1}/${date2}`);

      dispatch({
        type: GET_CALC,
        payload: res.data
      });
    } catch (err) {
      console.error(err);
    }
  };

  //Get lanes
  const getLanes = async calc => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    let formData = {};
    formData.city1 = calc.city1;
    formData.state1 = calc.state1;
    formData.city2 = calc.city2;
    formData.state2 = calc.state2;
    formData.equipment = calc.equipment;

    try {
      dispatch({
        type: LOADING,
        payload: null
      });

      const res = await axios.post("/api/loads/lane/history", formData, config);

      dispatch({
        type: GET_LANES,
        payload: res.data
      });
    } catch (err) {
      console.error(err);
    }
  };

  //Make quote to open
  const quote2Open = async id => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    let formData = {};
    formData.id = id;

    try {
      dispatch({
        type: LOADING,
        payload: null
      });

      const res = await axios.post(
        "/api/loads/quote-to-open",
        formData,
        config
      );

      dispatch({
        type: DELETE_OPEN_LOAD,
        payload: !state.afterDeleteOpen
      });
    } catch (err) {
      console.error(err);
    }
  };

  //Update user activity
  const updateActivity = async () => {
    try {
      const res = await axios.get(`/api/loads/update-user-activity`);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <loadContext.Provider
      value={{
        loading: state.loading,
        index: state.index,
        singleLoadPopUp: state.singleLoadPopUp,
        addOpenLoadPopUp: state.addOpenLoadPopUp,
        addQuotePopUp: state.addQuotePopUp,
        addCurrentLoadPopUp: state.addCurrentLoadPopUp,
        addMasterLoadPopUp: state.addMasterLoadPopUp,
        deleteLoadPopUp: state.deleteLoadPopUp,
        markLoadBookedPopUp: state.markLoadBookedPopUp,
        confirmDeliveryPopUp: state.confirmDeliveryPopUp,
        deleteCarrierPopUp: state.deleteCarrierPopUp,
        masterList: state.masterList,
        masterListNY: state.masterListNY,
        openLoadList: state.openLoadList,
        quoteLoadList: state.quoteLoadList,
        NCLoadList: state.NCLoadList,
        currentLoadList: state.currentLoadList,
        carrierList: state.carrierList,
        staffList: state.staffList,
        scannedLoadsCSV: state.scannedLoadsCSV,
        singleLoad: state.singleLoad,
        singleCarrier: state.singleCarrier,
        singleQuotePopUp: state.singleQuotePopUp,
        singleLoadId: state.singleLoadId,
        finalizePopUp: state.finalizePopUp,
        afterDeleteOpen: state.afterDeleteOpen,
        afterDeleteCurrent: state.afterDeleteCurrent,
        afterFeedback: state.afterFeedback,
        carrierFeedbackPopUp: state.carrierFeedbackPopUp,
        addCommentPopUp: state.addCommentPopUp,
        addCarrierPopUp: state.addCarrierPopUp,
        filterPopUp: state.filterPopUp,
        filterMC: state.filterMC,
        filter1: state.filter1,
        filter2: state.filter2,
        filter3: state.filter3,
        filter4: state.filter4,
        filter1Value: state.filter1Value,
        filter2Value: state.filter2Value,
        filter3Value: state.filter3Value,
        filter4Value: state.filter4Value,
        carrierWarningPopUp: state.carrierWarningPopUp,
        singleCarrierPopUp: state.singleCarrierPopUp,
        customLabelPopUp: state.customLabelPopUp,
        message: state.message,
        dupPopUp: state.dupPopUp,
        carrierInfo: state.carrierInfo,
        calcs: state.calcs,
        lanes: state.lanes,
        calcPopUp: state.calcPopUp,
        lanePopUp: state.lanePopUp,
        changeIndex,
        togglePopUp,
        addOpenLoad,
        getOpenLoads,
        removeOpenLoad,
        getCurrentLoads,
        removeCurrentLoad,
        toggleRemovePopUp,
        toggleMarkBookedPopUp,
        markLoadBooked,
        getCarriers,
        markDelivered,
        markScanned,
        changeSingleLoad,
        submitFeedback,
        getMasterLoads,
        markFinished,
        addLabel,
        getStaff,
        editLoad,
        addCurrentLoad,
        clearFilter,
        filterList,
        filterByMC,
        markLoadBooked2,
        check,
        getCalc,
        getLanes,
        getNCLoads,
        dupOpenLoad,
        revert,
        getQuoteLoads,
        addQuote,
        editQuote,
        removeQuote,
        quote2Open,
        getScansCSV,
        submitComment,
        changeSingleCarrier,
        addCarrier,
        deleteCarrier,
        editCarrier,
        deleteStaff,
        getMasterLoadsByYear,
        updateActivity
      }}
    >
      {props.children}
    </loadContext.Provider>
  );
};

export default LoadState;
