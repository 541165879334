import React, { useState, useContext, useEffect } from "react";
import "../cod/styles/auth.css";
import { Link } from "react-router-dom";
import AuthContext from "../context/auth/authContext";
import Spinner from "../utilities/Spinner";

const Register = props => {
  const authContext = useContext(AuthContext);
  const { register, error, loading, admin } = authContext;

  useEffect(() => {
    if (admin) {
      props.history.push(`/dashboard`);
    }
    // eslint-disable-next-line
  }, [error, admin]);

  const [user, setUser] = useState({
    name: "",
    email: "",
    position: "",
    password: "1234567",
    password2: "1234567",
    code: ""
  });

  const { name, email, position, password, password2, code } = user;

  const onChange = e => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const onSubmit = e => {
    e.preventDefault();
    if (name === "" || email === "" || code === "" || password === "") {
      alert("All fields must be entered");
    } else if (password !== password2) {
      alert("Passwords do not match");
    } else if (email.indexOf(" ") >= 0) {
      alert("Email can not contain spaces");
    } else {
      register({
        name,
        email,
        position,
        password,
        code
      });
    }
  };

  return (
    <div style={{ backgroundColor: "#DCCCBB" }} className="blubackCont">
      {loading ? <Spinner /> : null}
      <div style={{ backgroundColor: "#DCCCBB" }} className="loginShape4"></div>
      <div style={{ backgroundColor: "#DCCCBB" }} className="loginShape2"></div>
      <div style={{ backgroundColor: "#DCCCBB" }} className="loginShape6"></div>
      <div style={{ backgroundColor: "#DCCCBB" }} className="loginShape9"></div>
      <div className="authCont">
        <div className="authMiniCont">
          <h1 className="h1CreateAccount">Create a guest account</h1>
          <form onSubmit={onSubmit}>
            <div className="form-group fg1">
              <label htmlFor="name">Name</label>
              <input
                required
                type="text"
                name="name"
                value={name}
                onChange={onChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email address</label>
              <input
                required
                type="email"
                name="email"
                value={email}
                onChange={onChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="code">Guest code</label>
              <input
                required
                type="text"
                name="code"
                value={code}
                onChange={onChange}
              />
            </div>
            <input
              type="submit"
              value="Register"
              className="fg5 registerFormBTN"
            />
          </form>
        </div>
      </div>
      <div className="bottomTextP">
        <p style={{ color: "#333", fontWeight: "500" }}>
          Already have an account? Sign in{" "}
          <Link className="hereTextP" to={"/login/guest"}>
            here.
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Register;
