import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthContext from '../context/auth/authContext';

const PrivateRoute = ({ component: Component, ...rest }) => {

    const authContext = useContext(AuthContext);
    const { admin_access, loading } = authContext;

    return (
        <Route { ...rest } render={props => !admin_access && !loading ? (
            <Redirect to="/" />
        ) : (
            <Component {...props} />
        )
        } 
      />
    );
}

export default PrivateRoute
