import React, { useState, useEffect } from "react";
import DatePicker from "react-date-picker";

const SingleQuotePopUp = props => {
  const { load } = props;

  const [single, setLoad] = useState({
    today: new Date(),
    customer: load.customer,
    origin: load.origin,
    destination: load.destination,
    originState: load.origin_state,
    destinationState: load.destination_state,
    PUDate: new Date(load.pu_date_js),
    PUTime: load.pu_time,
    PUflex: load.pu_flex,
    DODate: new Date(load.do_date_js),
    DOTime: load.do_time,
    DOflex: load.do_flex,
    equipment: load.equipment,
    commodity: load.commodity,
    weight: load.weight,
    tarp: load.tarp,
    dims: load.dims,
    gwrate: load.gw_rate,
    truckrate: load.truck_rate,
    rateFlex: load.truck_rate_flex,
    notes: load.notes,
    loadID: load._id,
    googleMiles: load.new_google_miles,
    length: load.length,
    width: load.width,
    height: load.height,
    permits: load.permits,
    escorts: load.escorts,
    policeEscorts: load.police_escorts,
    insurance: load.insurance,
    surveys: load.surveys,
    bucketTrucks: load.bucketTrucks
  });

  const {
    customer,
    origin,
    destination,
    originState,
    destinationState,
    PUTime,
    PUflex,
    DOTime,
    DOflex,
    equipment,
    commodity,
    weight,
    tarp,
    dims,
    gwrate,
    truckrate,
    rateFlex,
    notes,
    PUDate,
    DODate,
    googleMiles,
    length,
    width,
    height,
    permits,
    escorts,
    policeEscorts,
    insurance,
    surveys,
    bucketTrucks
  } = single;

  const onChange = e => setLoad({ ...single, [e.target.name]: e.target.value });

  const onChangeCaps = e => setLoad({ ...single, [e.target.name]: e.target.value.toUpperCase() });

  const changeFlexPU = () => {
    setLoad({ ...single, PUflex: !PUflex });
  };
  const changeFlexDO = () => {
    setLoad({ ...single, DOflex: !DOflex });
  };
  const changeRateFlex = () => {
    setLoad({ ...single, rateFlex: !rateFlex });
  };
  const handlePUDate = e => {
    setLoad({ ...single, PUDate: e });
  };
  const handleDODate = e => {
    setLoad({ ...single, DODate: e });
  };
  const handleEquipment = e => {
    setLoad({ ...single, equipment: e.target.value });
  };

  const onPost = () => {
    props.add(single);
    props.toggle(16);
  };

  return (
    <div className="fixedPopUp">
      <div className="singleLoadPopUp">
        <div className="newInputRow1 inputRow">
          <div style={{width: "25%"}}>
            <label>Customer</label>
            <input
              type="text"
              name="customer"
              value={customer}
              onChange={onChangeCaps}
            />
          </div>
          <div style={{width: "23%"}}>
            <label>Origin City</label>
            <input
              type="text"
              name="origin"
              value={origin}
              onChange={onChangeCaps}
            />
          </div>
          <div style={{width: "10%"}}>
            <label>Origin State</label>
            <input
              type="text"
              name="originState"
              value={originState}
              onChange={onChangeCaps}
            />
          </div>
          <div style={{width: "23%"}}>
            <label>Destination City</label>
            <input
              type="text"
              name="destination"
              value={destination}
              onChange={onChangeCaps}
            />
          </div>
          <div style={{width: "10%"}}>
            <label>Dest. State</label>
            <input
              type="text"
              name="destinationState"
              value={destinationState}
              onChange={onChangeCaps}
            />
          </div>
        </div>
        <div className="inputRow2 inputRow">
          <div>
            <label>Pick-Up Date</label>
            <div className="datePickerDiv">
              <DatePicker
                value={PUDate}
                onChange={handlePUDate}
                clearIcon={null}
                calendarIcon={null}
                className="xxDatePicker"
              />
            </div>
          </div>
          <div>
            <label>Pick-Up Time</label>
            <input
              type="text"
              name="PUTime"
              value={PUTime}
              onChange={onChangeCaps}
            />
          </div>
          <div style={{ width: "5%" }}>
            <label>Flex?</label>
            <div onClick={changeFlexPU} className={PUflex ? "flexOn" : "flex"}>
              <i className="fas fa-check checkIconPopUp"></i>
            </div>
          </div>
          <i className="fas fa-long-arrow-alt-right arrowIcon"></i>
          <div>
            <label>Drop-Off Date</label>
            <div className="datePickerDiv">
              <DatePicker
                value={DODate}
                onChange={handleDODate}
                clearIcon={null}
                calendarIcon={null}
                className="xxDatePicker"
              />
            </div>
          </div>
          <div>
            <label>Drop-Off Time</label>
            <input
              type="text"
              name="DOTime"
              value={DOTime}
              onChange={onChangeCaps}
            />
          </div>
          <div style={{ width: "5%" }}>
            <label>Flex?</label>
            <div onClick={changeFlexDO} className={DOflex ? "flexOn" : "flex"}>
              <i className="fas fa-check checkIconPopUp"></i>
            </div>
          </div>
        </div>
        <div className="inputRow3 inputRow">
          <div>
            <label>Equipment</label>
            <select onChange={handleEquipment}>
            <option
                name="equipment"
                value="None"
                selected={equipment === "None" ? true : false}
              >
                None
              </option>
              <option
                name="equipment"
                value="Flatbed"
                selected={equipment === "Flatbed" ? true : false}
              >
                Flatbed
              </option>
              <option
                name="equipment"
                value="SD"
                selected={equipment === "SD" ? true : false}
              >
                SD
              </option>
              <option
                name="equipment"
                value="FSD"
                selected={equipment === "FSD" ? true : false}
              >
                FSD
              </option>
              <option
                name="equipment"
                value="53' flatbed"
                selected={equipment === "53' flatbed" ? true : false}
              >
                53' flatbed
              </option>
              <option
                name="equipment"
                value="53' SD"
                selected={equipment === "53' SD" ? true : false}
              >
                53' SD
              </option>
              <option
                name="equipment"
                value="53' SD Con"
                selected={equipment === "53' SD Con" ? true : false}
              >
                53' SD Con
              </option>
              <option
                name="equipment"
                value="53' flatbed Con"
                selected={equipment === "53' flatbed Con" ? true : false}
              >
                53' flatbed Con
              </option>
              <option
                name="equipment"
                value="53' Van"
                selected={equipment === "53' Van" ? true : false}
              >
                53' Van
              </option>
              <option
                name="equipment"
                value="48' flatbed Con"
                selected={equipment === "48' flatbed Con" ? true : false}
              >
                48' flatbed Con
              </option>
              <option
                name="equipment"
                value="48' SD Con"
                selected={equipment === "48' SD Con" ? true : false}
              >
                48' SD Con
              </option>
              <option
                name="equipment"
                value="40' hotshot"
                selected={equipment === "40' hotshot" ? true : false}
              >
                40' hotshot
              </option>
              <option
                name="equipment"
                value="30' hotshot"
                selected={equipment === "30' hotshot" ? true : false}
              >
                30' hotshot
              </option>
              <option
                name="equipment"
                value="26' box truck"
                selected={equipment === "26' box truck" ? true : false}
              >
                26' box truck
              </option>
              <option
                name="equipment"
                value="20' hotshot"
                selected={equipment === "20' hotshot" ? true : false}
              >
                20' hotshot
              </option>

              <option
                name="equipment"
                value="VR"
                selected={equipment === "VR" ? true : false}
              >
                VR
              </option>
              <option
                name="equipment"
                value="PO"
                selected={equipment === "PO" ? true : false}
              >
                PO
              </option>
              <option
                name="equipment"
                value="RGN"
                selected={equipment === "RGN" ? true : false}
              >
                RGN
              </option>
              <option
                name="equipment"
                value="DD"
                selected={equipment === "DD" ? true : false}
              >
                DD
              </option>
            </select>
          </div>
          <div>
            <label>Commodity</label>
            <input
              type="text"
              name="commodity"
              value={commodity}
              onChange={onChangeCaps}
            />
          </div>
          <div>
            <label>Weight</label>
            <input
              type="text"
              name="weight"
              value={weight}
              onChange={onChangeCaps}
            />
          </div>
          <div>
            <label>Tarp</label>
            <input type="text" name="tarp" value={tarp} onChange={onChangeCaps} />
          </div>
          <div>
            <label>Dims</label>
            <input type="text" name="dims" value={dims} onChange={onChangeCaps} />
          </div>
        </div>
        <div className="inputRow3 inputRow">
          <div>
            <label>Google Miles</label>
            <input
              type="number"
              name="googleMiles"
              value={googleMiles}
              onChange={onChange}
            />
          </div>
          <div>
            <label>Length</label>
            <input
              type="text"
              name="length"
              value={length}
              onChange={onChangeCaps}
            />
          </div>
          <div>
            <label>Width</label>
            <input type="text" name="width" value={width} onChange={onChangeCaps} />
          </div>
          <div>
            <label>Height</label>
            <input
              type="text"
              name="height"
              value={height}
              onChange={onChangeCaps}
            />
          </div>
          <div>
            <label>Permits</label>
            <input
              type="text"
              name="permits"
              value={permits}
              onChange={onChangeCaps}
            />
          </div>
        </div>
        <div className="inputRow3 inputRow">
          <div>
            <label>Escorts</label>
            <input
              type="text"
              name="escorts"
              value={escorts}
              onChange={onChangeCaps}
            />
          </div>
          <div>
            <label>Police Escorts</label>
            <input
              type="text"
              name="policeEscorts"
              value={policeEscorts}
              onChange={onChangeCaps}
            />
          </div>
          <div>
            <label>Insurance</label>
            <input
              type="text"
              name="insurance"
              value={insurance}
              onChange={onChangeCaps}
            />
          </div>
          <div>
            <label>Surveys</label>
            <input
              type="text"
              name="surveys"
              value={surveys}
              onChange={onChangeCaps}
            />
          </div>
          <div>
            <label>Bucket Trucks</label>
            <input
              type="text"
              name="bucketTrucks"
              value={bucketTrucks}
              onChange={onChangeCaps}
            />
          </div>
        </div>
        <div className="inputRow4 inputRow">
          <div>
            <label>Great Wide Rate</label>
            <input
              type="number"
              name="gwrate"
              value={gwrate}
              onChange={onChange}
            />
          </div>
          <div className="truckRateInputBigDiv">
            <div className="truckRateInput">
              <label>Truck Rate</label>
              <input
                type="number"
                name="truckrate"
                value={truckrate}
                onChange={onChange}
              />
            </div>
            <div className="truckRateInput" style={{ width: "30%" }}>
              <label style={{ marginLeft: "2vw" }}>Flexible?</label>
              <div
                onClick={changeRateFlex}
                className={rateFlex ? "flexOn" : "flex"}
              >
                <i className="fas fa-check checkIconPopUp"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="inputRow5 inputRow">
          <div>
            <label>Notes</label>
            <textarea
              type="text"
              name="notes"
              value={notes}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="btnRow">
          <div
            onClick={() => (props.remove(load._id), props.toggle(16))}
            className="delBtn"
          >
            Remove
          </div>
          <div className="btnRowFiller"></div>
          <div onClick={() => props.toggle(16)} className="cancelBtn">
            Exit
          </div>
          <div onClick={onPost} className="postBtn">
            Save
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleQuotePopUp;
