import React from "react";

const DupLoadPopUp = props => {
  return (
    <div className="fixedPopUp">
      <div className="confirmDeliveryPopUp">
        <h1>Create new open load?</h1>
        <div className="btnRow">
          <div className="btnRowFiller"></div>
          <div onClick={() => props.toggle(14)} className="cancelBtn">
            Cancel
          </div>
          <div onClick={() => (props.duplicate(props.load), props.toggle(14))} className="postBtn">
            Continue
          </div>
        </div>
      </div>
    </div>
  );
};

export default DupLoadPopUp;
