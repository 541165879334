import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_ERRORS,
  LOADING
} from "./types";

export default (state, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true
      }
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        admin: action.admin
    };
    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      localStorage.setItem("admin_access", action.payload.admin_access);
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true
      };
    case REGISTER_FAIL:
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT:
      localStorage.removeItem("admin_access");
      return {
        ...state,
        admin_access: null,
        isAuthenticated: false,
        loading: false,
        admin: null,
        error: action.payload
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null
      };
    default:
      return state;
  }
};
