import React, { useState, useEffect, useContext } from "react";
import LoadContext from "../../context/loads/loadContext";
import DatePicker from "react-date-picker";
import CarrierWarning from "../pop-ups/CarrierWarning";

const MarkBookedPopUp = props => {
  const loadContext = useContext(LoadContext);
  const { carrierWarningPopUp, singleLoad } = loadContext;

  const [carrier, setCarrier] = useState({
    name: "",
    mc: "",
    g: "",
    ptf: singleLoad.truck_rate,
    grossRate: singleLoad.gw_rate,
    equipment: singleLoad.equipment,
    PUTime: singleLoad.pu_time,
    email: "",
    dispatcher: "",
    phone: "",
    loadID: props.id
  });

  const {
    name,
    mc,
    ptf,
    grossRate,
    email,
    dispatcher,
    phone,
    loadID,
    PUTime,
    equipment,
    g
  } = carrier;

  const onChange = e =>
    setCarrier({ ...carrier, [e.target.name]: e.target.value.toUpperCase() });

  const onPost = () => {
    props.markBooked(carrier);
    props.toggle(5);
  };

  const handleEquipment = e => {
    setCarrier({ ...carrier, equipment: e.target.value });
  };

  return (
    <div className="fixedPopUp">
      <div className="markBookedPopUp">
        <div className="inputRow1MarkBooked inputRow">
          <div className="mcInputDiv">
            <label>G Number</label>
            <input type="text" name="g" value={g} onChange={onChange} />
          </div>
          <div className="carrierInputDiv">
            <label>Carrier</label>
            <input type="text" name="name" value={name} onChange={onChange} />
          </div>
          <div className="mcInputDiv">
            <label>MC Number</label>
            <input type="text" name="mc" value={mc} onChange={onChange} />
          </div>
        </div>

        <div className="inputRow3MarkBooked inputRow">
          <div>
            <label>Dispatcher</label>
            <input
              type="text"
              name="dispatcher"
              value={dispatcher}
              onChange={onChange}
            />
          </div>
          <div>
            <label>Email</label>
            <input type="text" name="email" value={email} onChange={onChange} />
          </div>
          <div>
            <label>Phone</label>
            <input type="text" name="phone" value={phone} onChange={onChange} />
          </div>
        </div>

        <div className="inputRow4MarkBooked inputRow">
          <div>
            <label>Equipment</label>
            <select onChange={handleEquipment}>
              <option
                name="equipment"
                value="None"
                selected={equipment === "None" ? true : false}
              >
                None
              </option>
              <option
                name="equipment"
                value="Flatbed"
                selected={equipment === "Flatbed" ? true : false}
              >
                Flatbed
              </option>
              <option
                name="equipment"
                value="SD"
                selected={equipment === "SD" ? true : false}
              >
                SD
              </option>
              <option
                name="equipment"
                value="FSD"
                selected={equipment === "FSD" ? true : false}
              >
                FSD
              </option>
              <option
                name="equipment"
                value="53' flatbed"
                selected={equipment === "53' flatbed" ? true : false}
              >
                53' flatbed
              </option>
              <option
                name="equipment"
                value="53' SD"
                selected={equipment === "53' SD" ? true : false}
              >
                53' SD
              </option>
              <option
                name="equipment"
                value="53' SD Con"
                selected={equipment === "53' SD Con" ? true : false}
              >
                53' SD Con
              </option>
              <option
                name="equipment"
                value="53' flatbed Con"
                selected={equipment === "53' flatbed Con" ? true : false}
              >
                53' flatbed Con
              </option>
              <option
                name="equipment"
                value="53' Van"
                selected={equipment === "53' Van" ? true : false}
              >
                53' Van
              </option>
              <option
                name="equipment"
                value="48' flatbed Con"
                selected={equipment === "48' flatbed Con" ? true : false}
              >
                48' flatbed Con
              </option>
              <option
                name="equipment"
                value="48' SD Con"
                selected={equipment === "48' SD Con" ? true : false}
              >
                48' SD Con
              </option>
              <option
                name="equipment"
                value="40' hotshot"
                selected={equipment === "40' hotshot" ? true : false}
              >
                40' hotshot
              </option>
              <option
                name="equipment"
                value="30' hotshot"
                selected={equipment === "30' hotshot" ? true : false}
              >
                30' hotshot
              </option>
              <option
                name="equipment"
                value="26' box truck"
                selected={equipment === "26' box truck" ? true : false}
              >
                26' box truck
              </option>
              <option
                name="equipment"
                value="20' hotshot"
                selected={equipment === "20' hotshot" ? true : false}
              >
                20' hotshot
              </option>

              <option
                name="equipment"
                value="VR"
                selected={equipment === "VR" ? true : false}
              >
                VR
              </option>
              <option
                name="equipment"
                value="PO"
                selected={equipment === "PO" ? true : false}
              >
                PO
              </option>
              <option
                name="equipment"
                value="RGN"
                selected={equipment === "RGN" ? true : false}
              >
                RGN
              </option>
              <option
                name="equipment"
                value="DD"
                selected={equipment === "DD" ? true : false}
              >
                DD
              </option>
            </select>
          </div>
          <div>
            <label>Pick Up Time</label>
            <input
              type="text"
              name="PUTime"
              value={PUTime}
              onChange={onChange}
            />
          </div>
        </div>

        <div className="inputRow2MarkBooked inputRow">
          <div>
            <label>GW rate</label>
            <input
              type="number"
              name="grossRate"
              value={grossRate}
              onChange={onChange}
            />
          </div>
          <div>
            <label>Truck rate</label>
            <input type="number" name="ptf" value={ptf} onChange={onChange} />
          </div>
          <div>
            <label>Margin</label>
            <input
              disabled
              type="number"
              name="margin"
              value={
                grossRate === 0 || ptf === 0
                  ? 0
                  : parseFloat((grossRate - ptf) / grossRate).toFixed(2)
              }
              onChange={onChange}
            />
          </div>
        </div>

        {/* 

        <div className="inputRow5 inputRow">
          <div>
            <label>Notes</label>
            <textarea
              type="text"
              name="notes"
              value={notes}
              onChange={onChange}
            />
          </div>
        </div> */}

        <div className="btnRowMarkBooked">
          <div className="btnRowFiller"></div>
          <div onClick={() => props.toggle(5)} className="cancelBtn">
            Cancel
          </div>
          <div onClick={onPost} className="saveBtn">
            Save
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarkBookedPopUp;
