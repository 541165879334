import React, { useContext, useEffect } from "react";
import AddCurrentLoadPopUp from "./pop-ups/AddCurrentLoadPopUp";
import LoadContext from "../context/loads/loadContext";
import ConfirmDeliveryPopUp from "./pop-ups/ConfirmDeliveryPopUp";
import CarrierFeedbackPopUp from "./pop-ups/CarrierFeedbackPopUp";
import SingleLoadPopUp from "./pop-ups/SingleLoadPopUp";
import FilterPopUp from "./pop-ups/FilterPopUp";

const CurrentLoads = props => {
  const loadContext = useContext(LoadContext);
  const {
    addCurrentLoadPopUp,
    togglePopUp,
    getCurrentLoads,
    afterDeleteCurrent,
    currentLoadList,
    confirmDeliveryPopUp,
    changeSingleLoad,
    markDelivered,
    carrierFeedbackPopUp,
    submitFeedback,
    afterFeedback,
    singleLoad,
    singleLoadPopUp,
    editLoad,
    addCurrentLoad,
    removeOpenLoad,
    markScanned,
    afterDeleteOpen,
    check,
    revert,
    addLabel,
    clearFilter,
    filterList,
    filterPopUp,
    filter1,
    filter1Value,
    filter2,
    filter2Value,
    filter3,
    filter3Value,
    filter4,
    filter4Value
  } = loadContext;

  useEffect(() => {
    getCurrentLoads();
  }, [afterDeleteCurrent, afterFeedback, afterDeleteOpen]);

  return (
    <React.Fragment>
      {addCurrentLoadPopUp ? (
        <AddCurrentLoadPopUp toggle={togglePopUp} add={addCurrentLoad} />
      ) : null}
      {confirmDeliveryPopUp ? (
        <ConfirmDeliveryPopUp
          toggle={togglePopUp}
          markDelivered={markDelivered}
          load={singleLoad}
        />
      ) : null}
      {carrierFeedbackPopUp ? (
        <CarrierFeedbackPopUp
          toggle={togglePopUp}
          submitFeedback={submitFeedback}
          load={singleLoad}
          revert={revert}
        />
      ) : null}
      {singleLoadPopUp ? (
        <SingleLoadPopUp
          toggle={togglePopUp}
          submitFeedback={submitFeedback}
          add={editLoad}
          load={singleLoad}
          open={false}
          quote={false}
          remove={removeOpenLoad}
          markScanned={markScanned}
          master={false}
          _admin={{ name: "nope" }}
          adminObj={props.adminObj}
          addLabel={addLabel}
        />
      ) : null}
      {filterPopUp ? (
        <FilterPopUp
          toggle={togglePopUp}
          filter={filterList}
          clear={clearFilter}
          source={"current"}
        />
      ) : null}
      <div className="toolBarContent">
        <div id="filter">
          <div onClick={() => togglePopUp(10)}>
            <i className="fas fa-filter searchIcon"></i>Filter
          </div>
        </div>
        <div onClick={() => togglePopUp(1)} id="addLoad">
          Add Current Load
        </div>
      </div>
      <div className="bigCurrentDiv">
        {currentLoadList.length === 0 ? (
          <h1>No current loads</h1>
        ) : filter4 !== "" ? (
          currentLoadList
            .filter(
              x =>
                x[filter1].toLowerCase() === filter1Value.toLowerCase() &&
                x[filter2].toLowerCase() === filter2Value.toLowerCase() &&
                x[filter3].toLowerCase() === filter3Value.toLowerCase() &&
                x[filter4].toLowerCase() === filter4Value.toLowerCase()
            )
            .map((x, i) => {
              return (
                <div
                  key={i}
                  onClick={() => (changeSingleLoad(x), togglePopUp(9))}
                  className="actualLoad actualCurrentLoad"
                >
                  <div className="titleActualLoad">
                    {`${x.origin}, ${x.origin_state} - ${x.destination}, ${x.destination_state}`}
                  </div>
                  <div className="secondAttribute">{x.g}</div>
                  <div className="firstAttribute">
                    {`${x.pu_date_display} - `} <span>{`${x.pu_time}`}</span>
                  </div>
                  <div className="secondAttribute">{x.customer}</div>
                  <div className="secondAttribute">{x.carrier}</div>
                  <div className="thirdAttribute">
                    ${parseFloat(x.gw_rate - x.truck_rate).toFixed(2)}{" "}
                    <span>({(x.margin * 100).toFixed(0)}%)</span>
                  </div>
                  <div className="fourthAttribute">
                    <i
                      onClick={() => (
                        togglePopUp(6), changeSingleLoad(x), togglePopUp(9)
                      )}
                      className="far fa-check-circle checkCircle"
                    ></i>
                    <i
                      onClick={() => (check(x), togglePopUp(9))}
                      className="far fa-bell checkCircle"
                      style={{ paddingLeft: "0.5vw" }}
                    ></i>
                  </div>
                </div>
              );
            })
        ) : filter3 !== "" ? (
          currentLoadList
            .filter(
              x =>
                x[filter1].toLowerCase() === filter1Value.toLowerCase() &&
                x[filter2].toLowerCase() === filter2Value.toLowerCase() &&
                x[filter3].toLowerCase() === filter3Value.toLowerCase()
            )
            .map((x, i) => {
              return (
                <div
                  key={i}
                  onClick={() => (changeSingleLoad(x), togglePopUp(9))}
                  className="actualLoad actualCurrentLoad"
                >
                  <div className="titleActualLoad">
                    {`${x.origin}, ${x.origin_state} - ${x.destination}, ${x.destination_state}`}
                  </div>
                  <div className="secondAttribute">{x.g}</div>
                  <div className="firstAttribute">
                    {`${x.pu_date_display} - `} <span>{`${x.pu_time}`}</span>
                  </div>
                  <div className="secondAttribute">{x.customer}</div>
                  <div className="secondAttribute">{x.carrier}</div>
                  <div className="thirdAttribute">
                    ${parseFloat(x.gw_rate - x.truck_rate).toFixed(2)}{" "}
                    <span>({(x.margin * 100).toFixed(0)}%)</span>
                  </div>
                  <div className="fourthAttribute">
                    <i
                      onClick={() => (
                        togglePopUp(6), changeSingleLoad(x), togglePopUp(9)
                      )}
                      className="far fa-check-circle checkCircle"
                    ></i>
                    <i
                      onClick={() => (check(x), togglePopUp(9))}
                      className="far fa-bell checkCircle"
                      style={{ paddingLeft: "0.5vw" }}
                    ></i>
                  </div>
                </div>
              );
            })
        ) : filter2 !== "" ? (
          currentLoadList
            .filter(
              x =>
                x[filter1].toLowerCase() === filter1Value.toLowerCase() &&
                x[filter2].toLowerCase() === filter2Value.toLowerCase()
            )
            .map((x, i) => {
              return (
                <div
                  key={i}
                  onClick={() => (changeSingleLoad(x), togglePopUp(9))}
                  className="actualLoad actualCurrentLoad"
                >
                  <div className="titleActualLoad">
                    {`${x.origin}, ${x.origin_state} - ${x.destination}, ${x.destination_state}`}
                  </div>
                  <div className="secondAttribute">{x.g}</div>
                  <div className="firstAttribute">
                    {`${x.pu_date_display} - `} <span>{`${x.pu_time}`}</span>
                  </div>
                  <div className="secondAttribute">{x.customer}</div>
                  <div className="secondAttribute">{x.carrier}</div>
                  <div className="thirdAttribute">
                    ${parseFloat(x.gw_rate - x.truck_rate).toFixed(2)}{" "}
                    <span>({(x.margin * 100).toFixed(0)}%)</span>
                  </div>
                  <div className="fourthAttribute">
                    <i
                      onClick={() => (
                        togglePopUp(6), changeSingleLoad(x), togglePopUp(9)
                      )}
                      className="far fa-check-circle checkCircle"
                    ></i>
                    <i
                      onClick={() => (check(x), togglePopUp(9))}
                      className="far fa-bell checkCircle"
                      style={{ paddingLeft: "0.5vw" }}
                    ></i>
                  </div>
                </div>
              );
            })
        ) : filter1 !== "" ? (
          currentLoadList
            .filter(
              x => x[filter1].toLowerCase() === filter1Value.toLowerCase()
            )
            .map((x, i) => {
              return (
                <div
                  key={i}
                  onClick={() => (changeSingleLoad(x), togglePopUp(9))}
                  className="actualLoad actualCurrentLoad"
                >
                  <div className="titleActualLoad">
                    {`${x.origin}, ${x.origin_state} - ${x.destination}, ${x.destination_state}`}
                  </div>
                  <div className="secondAttribute">{x.g}</div>
                  <div className="firstAttribute">
                    {`${x.pu_date_display} - `} <span>{`${x.pu_time}`}</span>
                  </div>
                  <div className="secondAttribute">{x.customer}</div>
                  <div className="secondAttribute">{x.carrier}</div>
                  <div className="thirdAttribute">
                    ${parseFloat(x.gw_rate - x.truck_rate).toFixed(2)}{" "}
                    <span>({(x.margin * 100).toFixed(0)}%)</span>
                  </div>
                  <div className="fourthAttribute">
                    <i
                      onClick={() => (
                        togglePopUp(6), changeSingleLoad(x), togglePopUp(9)
                      )}
                      className="far fa-check-circle checkCircle"
                    ></i>
                    <i
                      onClick={() => (check(x), togglePopUp(9))}
                      className="far fa-bell checkCircle"
                      style={{ paddingLeft: "0.5vw" }}
                    ></i>
                  </div>
                </div>
              );
            })
        ) : (
          currentLoadList.map((x, i) => {
            return (
              <div
                key={i}
                onClick={() => (changeSingleLoad(x), togglePopUp(9))}
                className="actualLoad actualCurrentLoad"
              >
                <div className="titleActualLoad">
                  {`${x.origin}, ${x.origin_state} - ${x.destination}, ${x.destination_state}`}
                </div>
                <div className="secondAttribute">{x.g}</div>
                <div className="firstAttribute">
                  {`${x.pu_date_display} - `} <span>{`${x.pu_time}`}</span>
                </div>
                <div className="secondAttribute">{x.customer}</div>
                <div className="secondAttribute">{x.carrier}</div>
                <div className="thirdAttribute">
                  ${parseFloat(x.gw_rate - x.truck_rate).toFixed(2)}{" "}
                  <span>({(x.margin * 100).toFixed(0)}%)</span>
                </div>
                <div className="fourthAttribute">
                  <i
                    onClick={() => (
                      togglePopUp(6), changeSingleLoad(x), togglePopUp(9)
                    )}
                    className="far fa-check-circle checkCircle"
                  ></i>
                  <i
                    onClick={() => (check(x), togglePopUp(9))}
                    className="far fa-bell checkCircle"
                    style={{ paddingLeft: "0.5vw" }}
                  ></i>
                </div>
              </div>
            );
          })
        )}
      </div>
    </React.Fragment>
  );
};

export default CurrentLoads;
