import React, { useState } from "react";

const FilterPopUp = props => {
  const [filt, setFilt] = useState(
    props.source === "master" || props.source === "current"
      ? {
          filter1bool: true,
          filter2bool: false,
          filter3bool: false,
          filter4bool: false,
          filter1: "g",
          filter2: "g",
          filter3: "g",
          filter4: "g",
          filter1Value: "",
          filter2Value: "",
          filter3Value: "",
          filter4Value: ""
        }
      : props.source === "quote"
      ? {
          filter1bool: true,
          filter2bool: false,
          filter3bool: false,
          filter4bool: false,
          filter1: "customer",
          filter2: "customer",
          filter3: "customer",
          filter4: "customer",
          filter1Value: "",
          filter2Value: "",
          filter3Value: "",
          filter4Value: ""
        }
      : {
          filter1bool: true,
          filter2bool: false,
          filter3bool: false,
          filter4bool: false,
          filter1: "g",
          filter2: "g",
          filter3: "g",
          filter4: "g",
          filter1Value: "",
          filter2Value: "",
          filter3Value: "",
          filter4Value: ""
        }
  );

  const {
    filter1bool,
    filter2bool,
    filter3bool,
    filter4bool,
    filter1,
    filter2,
    filter3,
    filter4,
    filter1Value,
    filter2Value,
    filter3Value,
    filter4Value
  } = filt;

  const onChange = e => setFilt({ ...filt, [e.target.name]: e.target.value });

  const handleSelect1 = e => {
    setFilt({ ...filt, filter1: e.target.value });
  };
  const handleSelect2 = e => {
    setFilt({ ...filt, filter2: e.target.value });
  };
  const handleSelect3 = e => {
    setFilt({ ...filt, filter3: e.target.value });
  };
  const handleSelect4 = e => {
    setFilt({ ...filt, filter4: e.target.value });
  };

  const onAddFilter = () => {
    if (filter2bool && !filter3bool) {
      setFilt({ ...filt, filter3bool: true });
    } else if (filter3bool) {
      setFilt({ ...filt, filter4bool: true });
    } else {
      setFilt({ ...filt, filter2bool: true });
    }
  };

  return (
    <div className="fixedPopUp">
      {props.source === "master" || props.source === "current" ? (
        <div className="filterLoadPopUp">
          <div>
            <h1>Filter Loads</h1>
            <p
              style={filter4bool ? { display: "none" } : null}
              onClick={onAddFilter}
              className="addFilterP"
            >
              + add filter
            </p>
          </div>

          <div className="filterInputRow">
            <div className="filterSelectDiv">
              <select onChange={handleSelect1}>
                <option name="filter1" value="g">
                  G Number
                </option>
                <option name="filter1" value="mc">
                  MC Number
                </option>
                <option name="filter1" value="carrier">
                  Carrier
                </option>
                <option name="filter1" value="customer">
                  Customer
                </option>
                <option name="filter1" value="origin">
                  Origin
                </option>
                <option name="filter1" value="destination">
                  Destination
                </option>
                <option name="filter1" value="origin_state">
                  Origin State
                </option>
                <option name="filter1" value="destination_state">
                  Destination State
                </option>
                <option name="filter1" value="commodity">
                  Commodity
                </option>
                <option name="filter1" value="dims">
                  Dims
                </option>
                <option name="filter1" value="equipment">
                  Equipment
                </option>
                <option name="filter1" value="tarp">
                  Tarp
                </option>
                <option name="filter1" value="weight">
                  Weight
                </option>
              </select>
            </div>
            <div className="carrierInputDiv2">
              <input
                type="text"
                name="filter1Value"
                value={filter1Value}
                onChange={onChange}
              />
            </div>
          </div>

          <div
            style={filter2bool ? null : { display: "none" }}
            className="filterInputRow"
          >
            <div className="filterSelectDiv">
              <select onChange={handleSelect2}>
                <option name="filter2" value="g">
                  G Number
                </option>
                <option name="filter2" value="mc">
                  MC Number
                </option>
                <option name="filter2" value="carrier">
                  Carrier
                </option>
                <option name="filter2" value="customer">
                  Customer
                </option>
                <option name="filter2" value="origin">
                  Origin
                </option>
                <option name="filter2" value="destination">
                  Destination
                </option>
                <option name="filter2" value="origin_state">
                  Origin State
                </option>
                <option name="filter2" value="destination_state">
                  Destination State
                </option>
                <option name="filter2" value="commodity">
                  Commodity
                </option>
                <option name="filter2" value="dims">
                  Dims
                </option>
                <option name="filter2" value="equipment">
                  Equipment
                </option>
                <option name="filter2" value="tarp">
                  Tarp
                </option>
                <option name="filter2" value="weight">
                  Weight
                </option>
              </select>
            </div>
            <div className="carrierInputDiv2">
              <input
                type="text"
                name="filter2Value"
                value={filter2Value}
                onChange={onChange}
              />
            </div>
          </div>
          <div
            style={filter3bool ? null : { display: "none" }}
            className="filterInputRow"
          >
            <div className="filterSelectDiv">
              <select onChange={handleSelect3}>
                <option name="filter1" value="g">
                  G Number
                </option>
                <option name="filter1" value="mc">
                  MC Number
                </option>
                <option name="filter1" value="carrier">
                  Carrier
                </option>
                <option name="filter1" value="customer">
                  Customer
                </option>
                <option name="filter1" value="origin">
                  Origin
                </option>
                <option name="filter1" value="destination">
                  Destination
                </option>
                <option name="filter1" value="origin_state">
                  Origin State
                </option>
                <option name="filter1" value="destination_state">
                  Destination State
                </option>
                <option name="filter1" value="commodity">
                  Commodity
                </option>
                <option name="filter1" value="dims">
                  Dims
                </option>
                <option name="filter1" value="equipment">
                  Equipment
                </option>
                <option name="filter1" value="tarp">
                  Tarp
                </option>
                <option name="filter1" value="weight">
                  Weight
                </option>
              </select>
            </div>
            <div className="carrierInputDiv2">
              <input
                type="text"
                name="filter3Value"
                value={filter3Value}
                onChange={onChange}
              />
            </div>
          </div>
          <div
            style={filter4bool ? null : { display: "none" }}
            className="filterInputRow"
          >
            <div className="filterSelectDiv">
              <select onChange={handleSelect4}>
                <option name="filter1" value="g">
                  G Number
                </option>
                <option name="filter1" value="mc">
                  MC Number
                </option>
                <option name="filter1" value="carrier">
                  Carrier
                </option>
                <option name="filter1" value="customer">
                  Customer
                </option>
                <option name="filter1" value="origin">
                  Origin
                </option>
                <option name="filter1" value="destination">
                  Destination
                </option>
                <option name="filter1" value="origin_state">
                  Origin State
                </option>
                <option name="filter1" value="destination_state">
                  Destination State
                </option>
                <option name="filter1" value="commodity">
                  Commodity
                </option>
                <option name="filter1" value="dims">
                  Dims
                </option>
                <option name="filter1" value="equipment">
                  Equipment
                </option>
                <option name="filter1" value="tarp">
                  Tarp
                </option>
                <option name="filter1" value="weight">
                  Weight
                </option>
              </select>
            </div>
            <div className="carrierInputDiv2">
              <input
                type="text"
                name="filter4Value"
                value={filter4Value}
                onChange={onChange}
              />
            </div>
          </div>

          <div className="btnRow">
            <div
              onClick={() => (props.clear(), props.toggle(10))}
              className="cancelBtn"
            >
              Cancel/Clear
            </div>
            <div onClick={() => props.filter(filt)} className="postBtn">
              Search
            </div>
          </div>
        </div>
      ) : props.source === "quote" ? (
        <div className="filterLoadPopUp">
          <div>
            <h1>Filter Quotes</h1>
            <p
              style={filter4bool ? { display: "none" } : null}
              onClick={onAddFilter}
              className="addFilterP"
            >
              + add filter
            </p>
          </div>

          <div className="filterInputRow">
            <div className="filterSelectDiv">
              <select onChange={handleSelect1}>
                <option name="filter1" value="customer">
                  Customer
                </option>
                <option name="filter1" value="origin">
                  Origin
                </option>
                <option name="filter1" value="destination">
                  Destination
                </option>
                <option name="filter1" value="origin_state">
                  Origin State
                </option>
                <option name="filter1" value="destination_state">
                  Destination State
                </option>
                <option name="filter1" value="commodity">
                  Commodity
                </option>
                <option name="filter1" value="dims">
                  Dims
                </option>
                <option name="filter1" value="equipment">
                  Equipment
                </option>
                <option name="filter1" value="tarp">
                  Tarp
                </option>
                <option name="filter1" value="weight">
                  Weight
                </option>
                <option name="filter1" value="length">
                  Length
                </option>
                <option name="filter1" value="width">
                  Width
                </option>
                <option name="filter1" value="height">
                  Height
                </option>
                <option name="filter1" value="permits">
                  Permits
                </option>
                <option name="filter1" value="escorts">
                  Escorts
                </option>
                <option name="filter1" value="police_escorts">
                  Police Escorts
                </option>
                <option name="filter1" value="insurance">
                  Insurance
                </option>
                <option name="filter1" value="surveys">
                  Surveys
                </option>
                <option name="filter1" value="bucket_trucks">
                  Bucket Trucks
                </option>
              </select>
            </div>
            <div className="carrierInputDiv2">
              <input
                type="text"
                name="filter1Value"
                value={filter1Value}
                onChange={onChange}
              />
            </div>
          </div>

          <div
            style={filter2bool ? null : { display: "none" }}
            className="filterInputRow"
          >
            <div className="filterSelectDiv">
              <select onChange={handleSelect2}>
                <option name="filter1" value="customer">
                  Customer
                </option>
                <option name="filter1" value="origin">
                  Origin
                </option>
                <option name="filter1" value="destination">
                  Destination
                </option>
                <option name="filter1" value="origin_state">
                  Origin State
                </option>
                <option name="filter1" value="destination_state">
                  Destination State
                </option>
                <option name="filter1" value="commodity">
                  Commodity
                </option>
                <option name="filter1" value="dims">
                  Dims
                </option>
                <option name="filter1" value="equipment">
                  Equipment
                </option>
                <option name="filter1" value="tarp">
                  Tarp
                </option>
                <option name="filter1" value="weight">
                  Weight
                </option>
                <option name="filter1" value="length">
                  Length
                </option>
                <option name="filter1" value="width">
                  Width
                </option>
                <option name="filter1" value="height">
                  Height
                </option>
                <option name="filter1" value="permits">
                  Permits
                </option>
                <option name="filter1" value="escorts">
                  Escorts
                </option>
                <option name="filter1" value="police_escorts">
                  Police Escorts
                </option>
                <option name="filter1" value="insurance">
                  Insurance
                </option>
                <option name="filter1" value="surveys">
                  Surveys
                </option>
                <option name="filter1" value="bucket_trucks">
                  Bucket Trucks
                </option>
              </select>
            </div>
            <div className="carrierInputDiv2">
              <input
                type="text"
                name="filter2Value"
                value={filter2Value}
                onChange={onChange}
              />
            </div>
          </div>
          <div
            style={filter3bool ? null : { display: "none" }}
            className="filterInputRow"
          >
            <div className="filterSelectDiv">
              <select onChange={handleSelect3}>
                <option name="filter1" value="customer">
                  Customer
                </option>
                <option name="filter1" value="origin">
                  Origin
                </option>
                <option name="filter1" value="destination">
                  Destination
                </option>
                <option name="filter1" value="origin_state">
                  Origin State
                </option>
                <option name="filter1" value="destination_state">
                  Destination State
                </option>
                <option name="filter1" value="commodity">
                  Commodity
                </option>
                <option name="filter1" value="dims">
                  Dims
                </option>
                <option name="filter1" value="equipment">
                  Equipment
                </option>
                <option name="filter1" value="tarp">
                  Tarp
                </option>
                <option name="filter1" value="weight">
                  Weight
                </option>
                <option name="filter1" value="length">
                  Length
                </option>
                <option name="filter1" value="width">
                  Width
                </option>
                <option name="filter1" value="height">
                  Height
                </option>
                <option name="filter1" value="permits">
                  Permits
                </option>
                <option name="filter1" value="escorts">
                  Escorts
                </option>
                <option name="filter1" value="police_escorts">
                  Police Escorts
                </option>
                <option name="filter1" value="insurance">
                  Insurance
                </option>
                <option name="filter1" value="surveys">
                  Surveys
                </option>
                <option name="filter1" value="bucket_trucks">
                  Bucket Trucks
                </option>
              </select>
            </div>
            <div className="carrierInputDiv2">
              <input
                type="text"
                name="filter3Value"
                value={filter3Value}
                onChange={onChange}
              />
            </div>
          </div>
          <div
            style={filter4bool ? null : { display: "none" }}
            className="filterInputRow"
          >
            <div className="filterSelectDiv">
              <select onChange={handleSelect4}>
                <option name="filter1" value="customer">
                  Customer
                </option>
                <option name="filter1" value="origin">
                  Origin
                </option>
                <option name="filter1" value="destination">
                  Destination
                </option>
                <option name="filter1" value="origin_state">
                  Origin State
                </option>
                <option name="filter1" value="destination_state">
                  Destination State
                </option>
                <option name="filter1" value="commodity">
                  Commodity
                </option>
                <option name="filter1" value="dims">
                  Dims
                </option>
                <option name="filter1" value="equipment">
                  Equipment
                </option>
                <option name="filter1" value="tarp">
                  Tarp
                </option>
                <option name="filter1" value="weight">
                  Weight
                </option>
                <option name="filter1" value="length">
                  Length
                </option>
                <option name="filter1" value="width">
                  Width
                </option>
                <option name="filter1" value="height">
                  Height
                </option>
                <option name="filter1" value="permits">
                  Permits
                </option>
                <option name="filter1" value="escorts">
                  Escorts
                </option>
                <option name="filter1" value="police_escorts">
                  Police Escorts
                </option>
                <option name="filter1" value="insurance">
                  Insurance
                </option>
                <option name="filter1" value="surveys">
                  Surveys
                </option>
                <option name="filter1" value="bucket_trucks">
                  Bucket Trucks
                </option>
              </select>
            </div>
            <div className="carrierInputDiv2">
              <input
                type="text"
                name="filter4Value"
                value={filter4Value}
                onChange={onChange}
              />
            </div>
          </div>

          <div className="btnRow">
            <div
              onClick={() => (props.clear(), props.toggle(10))}
              className="cancelBtn"
            >
              Cancel/Clear
            </div>
            <div onClick={() => props.filter(filt)} className="postBtn">
              Search
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default FilterPopUp;
