import {
  CHANGE_INDEX,
  LOADING,
  TOGGLE_POPUP,
  ADD_OPEN_LOAD,
  GET_OPEN_LOADS,
  DELETE_OPEN_LOAD,
  TOGGLE_REMOVE_POPUP,
  TOGGLE_MARK_BOOKED_POPUP,
  GET_CURRENT_LOADS,
  GET_NC_LOADS,
  DELETE_CURRENT_LOAD,
  GET_CARRIERS,
  CHANGE_SINGLE_LOAD,
  AFTER_FEEDBACK,
  GET_MASTER_LOADS,
  GET_STAFF,
  CLEAR,
  SET_FILTER,
  SET_FILTER_MC,
  WARNING,
  SET_CARRIER,
  GET_SCANNED_CSV,
  GET_CALC,
  GET_LANES,
  GET_QUOTE_LOADS,
  ADD_QUOTE,
  CHANGE_SINGLE_CARRIER,
  GET_MASTER_LOADS_NY,
  ADD_CURRENT_LOAD
} from "./types";

export default (state, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true
      };
    case CHANGE_INDEX:
      return {
        ...state,
        index: action.payload
      };
    case TOGGLE_POPUP:
      if (action.payload === 1) {
        return {
          ...state,
          addCurrentLoadPopUp: !state.addCurrentLoadPopUp
        };
      } else if (action.payload === 2) {
        return {
          ...state,
          addOpenLoadPopUp: !state.addOpenLoadPopUp
        };
      } else if (action.payload === 3) {
        return {
          ...state,
          addMasterLoadPopUp: !state.addMasterLoadPopUp
        };
      } else if (action.payload === 4) {
        return {
          ...state,
          deleteLoadPopUp: !state.deleteLoadPopUp
        };
      } else if (action.payload === 5) {
        return {
          ...state,
          markLoadBookedPopUp: !state.markLoadBookedPopUp
        };
      } else if (action.payload === 6) {
        return {
          ...state,
          confirmDeliveryPopUp: !state.confirmDeliveryPopUp
        };
      } else if (action.payload === 7) {
        return {
          ...state,
          carrierFeedbackPopUp: !state.carrierFeedbackPopUp
        };
      } else if (action.payload === 8) {
        return {
          ...state,
          finalizePopUp: !state.finalizePopUp
        };
      } else if (action.payload === 9) {
        return {
          ...state,
          singleLoadPopUp: !state.singleLoadPopUp
        };
      } else if (action.payload === 10) {
        return {
          ...state,
          filterPopUp: !state.filterPopUp
        };
      } else if (action.payload === 11) {
        return {
          ...state,
          carrierWarningPopUp: !state.carrierWarningPopUp
        };
      } else if (action.payload === 12) {
        return {
          ...state,
          calcPopUp: !state.calcPopUp
        };
      } else if (action.payload === 13) {
        return {
          ...state,
          lanePopUp: !state.lanePopUp
        };
      } else if (action.payload === 14) {
        return {
          ...state,
          dupPopUp: !state.dupPopUp
        };
      } else if (action.payload === 15) {
        return {
          ...state,
          addQuotePopUp: !state.addQuotePopUp
        };
      } else if (action.payload === 16) {
        return {
          ...state,
          singleQuotePopUp: !state.singleQuotePopUp
        };
      } else if (action.payload === 17) {
        return {
          ...state,
          addCommentPopUp: !state.addCommentPopUp
        };
      } else if (action.payload === 18) {
        return {
          ...state,
          addCarrierPopUp: !state.addCarrierPopUp
        };
      } else if (action.payload === 19) {
        return {
          ...state,
          deleteCarrierPopUp: !state.deleteCarrierPopUp
        };
      } else if (action.payload === 20) {
        return {
          ...state,
          singleCarrierPopUp: !state.singleCarrierPopUp
        };
      } else if (action.payload === 21) {
        return {
          ...state,
          customLabelPopUp: !state.customLabelPopUp
        };
      } else {
        return {
          ...state
        };
      }
    case TOGGLE_REMOVE_POPUP:
      return {
        ...state,
        deleteLoadPopUp: !state.deleteLoadPopUp,
        singleLoadId: action.payload
      };
    case TOGGLE_MARK_BOOKED_POPUP:
      return {
        ...state,
        markLoadBookedPopUp: !state.markLoadBookedPopUp,
        singleLoadId: action.payload
      };
    case CHANGE_SINGLE_LOAD:
      return {
        ...state,
        singleLoad: action.payload
      };
    case CHANGE_SINGLE_CARRIER:
      return {
        ...state,
        singleCarrier: action.payload
      };
    case ADD_OPEN_LOAD:
      return {
        ...state,
        addOpenLoadPopUp: false,
        openLoadList: [...state.openLoadList, action.payload],
        loading: false
      };
    case ADD_CURRENT_LOAD:
      return {
        ...state,
        addCurrentLoadPopUp: false,
        currentLoadList: [...state.currentLoadList, action.payload],
        loading: false
      };
    case GET_OPEN_LOADS:
      return {
        ...state,
        openLoadList: action.payload,
        loading: false
      };
    case ADD_QUOTE:
      return {
        ...state,
        addOpenLoadPopUp: false,
        quoteLoadList: [action.payload, ...state.quoteLoadList],
        loading: false
      };
    case GET_QUOTE_LOADS:
      return {
        ...state,
        quoteLoadList: action.payload,
        loading: false
      };
    case DELETE_OPEN_LOAD:
      return {
        ...state,
        afterDeleteOpen: action.payload,
        deleteLoadPopUp: false,
        carrierWarningPopUp: false
      };
    case GET_MASTER_LOADS:
      return {
        ...state,
        masterList: action.payload,
        loading: false
      };
    case GET_MASTER_LOADS_NY:
      return {
        ...state,
        masterListNY: action.payload,
        loading: false
      };
    case GET_CURRENT_LOADS:
      return {
        ...state,
        currentLoadList: action.payload,
        loading: false
      };
    case GET_NC_LOADS:
      return {
        ...state,
        NCLoadList: action.payload,
        loading: false
      };
    case DELETE_CURRENT_LOAD:
      return {
        ...state,
        afterDeleteCurrent: action.payload,
        deleteLoadPopUp: false,
        singleLoadPopUp: false,
        confirmDeliveryPopUp: false
      };
    case GET_CARRIERS:
      return {
        ...state,
        carrierList: action.payload,
        loading: false
      };
    case GET_SCANNED_CSV:
      return {
        ...state,
        scannedLoadsCSV: action.csv
      };
    case AFTER_FEEDBACK:
      return {
        ...state,
        afterFeedback: action.payload
      };
    case GET_STAFF:
      return {
        ...state,
        staffList: action.payload,
        loading: false
      };
    case CLEAR:
      return {
        ...state,
        filter1: "",
        filter2: "",
        filter3: "",
        filter4: "",
        filter1Value: "",
        filter2Value: "",
        filter3Value: "",
        filter4Value: ""
      };
    case SET_FILTER:
      if (action.payload.filter4bool) {
        return {
          ...state,
          afterDeleteCurrent: !state.afterDeleteCurrent,
          filterPopUp: false,
          filter1: action.payload.filter1,
          filter2: action.payload.filter2,
          filter3: action.payload.filter3,
          filter4: action.payload.filter4,
          filter1Value: action.payload.filter1Value,
          filter2Value: action.payload.filter2Value,
          filter3Value: action.payload.filter3Value,
          filter4Value: action.payload.filter4Value
        };
      } else if (action.payload.filter3bool) {
        return {
          ...state,
          afterDeleteCurrent: !state.afterDeleteCurrent,
          filterPopUp: false,
          filter1: action.payload.filter1,
          filter2: action.payload.filter2,
          filter3: action.payload.filter3,
          filter4: "",
          filter1Value: action.payload.filter1Value,
          filter2Value: action.payload.filter2Value,
          filter3Value: action.payload.filter3Value,
          filter4Value: ""
        };
      } else if (action.payload.filter2bool) {
        return {
          ...state,
          afterDeleteCurrent: !state.afterDeleteCurrent,
          filterPopUp: false,
          filter1: action.payload.filter1,
          filter2: action.payload.filter2,
          filter3: "",
          filter4: "",
          filter1Value: action.payload.filter1Value,
          filter2Value: action.payload.filter2Value,
          filter3Value: "",
          filter4Value: ""
        };
      } else {
        return {
          ...state,
          afterDeleteCurrent: !state.afterDeleteCurrent,
          filterPopUp: false,
          filter1: action.payload.filter1,
          filter2: "",
          filter3: "",
          filter4: "",
          filter1Value: action.payload.filter1Value,
          filter2Value: "",
          filter3Value: "",
          filter4Value: ""
        };
      }
    case SET_FILTER_MC:
      return {
        ...state,
        afterDeleteCurrent: !state.afterDeleteCurrent,
        filterMC: action.payload.number
      };
    case SET_CARRIER:
      return {
        ...state,
        carrierInfo: action.payload
      };
    case WARNING:
      return {
        ...state,
        carrierWarningPopUp: !state.carrierWarningPopUp,
        message: action.payload,
        loading: false
      };
    case GET_CALC:
      return {
        ...state,
        calcs: action.payload,
        loading: false
      };
    case GET_LANES:
      return {
        ...state,
        lanes: action.payload,
        loading: false
      };
    default:
      return state;
  }
};
