import React, { useState, useContext, useEffect } from "react";
import LoadContext from "../../context/loads/loadContext";

const LanePopUp = props => {
  const loadContext = useContext(LoadContext);
  const { getLanes, togglePopUp, lanes } = loadContext;

  const [calc, setCalc] = useState({
    state1: "",
    city1: "",
    state2: "",
    city2: "",
    equipment: ""
  });

  const { state1, state2, city1, city2, equipment } = calc;

  const onChange = e => setCalc({ ...calc, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();
    getLanes(calc);
  };

  return (
    <div className="absPopUp">
      <div className="lanePopUp">
        <div className="cancelNPrintDiv">
          <i onClick={() => togglePopUp(13)} className="fas fa-times"></i>
        </div>
        <h1 className="searchH1">Search previous lanes (create email list)</h1>
        <div className="searchstatesDiv">
          <div className="statePickerDivSearchHours">
            <input
              placeholder="From (City)"
              name="city1"
              value={city1}
              onChange={onChange}
            />
          </div>
          <div className="statePickerDivSearchHours">
            <input
              placeholder="From (State)"
              name="state1"
              value={state1}
              onChange={onChange}
            />
          </div>
          <div className="statePickerDivSearchHours">
            <input
              placeholder="To (City)"
              name="city2"
              value={city2}
              onChange={onChange}
            />
          </div>
          <div className="statePickerDivSearchHours">
            <input
              placeholder="To (State)"
              name="state2"
              value={state2}
              onChange={onChange}
            />
          </div>
          <div className="statePickerDivSearchHours">
            <input
              placeholder="Equipment"
              name="equipment"
              value={equipment}
              onChange={onChange}
            />
          </div>
          <div onClick={onSubmit} className="searchBtnDivSearchHours">
            Search
          </div>
        </div>
        <div className="laneHistory">
          {lanes.length === 0 ? (
            <h1 className="searchH1">0 carriers</h1>
          ) : (
            lanes.map((x, i) => {
              return (
                <div className="singleSearchedLane" key={i}>
                  <p>
                    {x}
                    {i === lanes.length - 1 ? "" : ", "}
                  </p>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default LanePopUp;
