import React, { Fragment } from 'react';
import spinner2 from './load3.gif';

export default (props) => (
    <div style={{position: 'fixed', left: '0', top: '0', width: '100vw', height: '100vh', backgroundColor: '#cccccc82', zIndex: '500'}}>
        <img 
            style={{ width: '10vw', borderRadius: '25px', marginTop: '15vw', marginLeft: '45vw', display: 'block' }}
            src={spinner2}
            alt='Loading...'
        />
    </div>
)